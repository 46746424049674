import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { NavigationEnd, Router } from '@angular/router';
import * as fs from 'file-saver';
import { HttpService } from './http.service';
const { Parser } = require('json2csv');
import { NotificationsService } from 'angular2-notifications';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
    claimManagementClicked = false;
    consolidatedClicked = false;
    previousUrl: any;
    currentUrl: any;

  constructor(private router: Router, private httpService:HttpService,private _notifications: NotificationsService) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
   }
    _claimManagementClicked(){
        this.claimManagementClicked = true;
    }
    _claimManagementAway(){
        this.claimManagementClicked = false;
    }
    _consolidatedClicked(){
      this.consolidatedClicked = true;
    }
    _consolidatedAway(){
        this.consolidatedClicked = false;
    }
    prepareTableDataForCsv(dataSource:any,columnToDisplay:any,columnsOption:any,paginator:MatPaginator,currency?:any){
      console.log(dataSource)
      let copyOfDataSource = JSON.parse(JSON.stringify(dataSource.data));
      
      /**To extract date by pagination of current page*/
        // const skip = paginator.pageSize * paginator.pageIndex;
        // var paged = copyOfDataSource.filter((u:any, i:any) => i >= skip)
        //             .filter((u:any, i:any) => i <paginator.pageSize);
      /** End */

      var paged = copyOfDataSource; /**Foll all table data */ 
      let columIdToRemove: string[]=[]
      paged.forEach((pelement:any) => {
        for (const property in pelement) {
          if(property.includes('amount') || property.includes('Amount')){
            if(pelement['currency']){
              pelement[property] = pelement[property].toString()+" "+pelement['currency'];
            }else{
              pelement[property] = pelement[property].toString()+" "+currency; 
            }
          }
        }
      });

      columnsOption.forEach((element:any) => {
        if(!columnToDisplay.includes(element.id)){
          columIdToRemove.push(element.id);
        }
      });
      columIdToRemove.forEach(element => {
        paged.forEach((pelement:any) => {
          delete pelement[element];
        });
      });

        return paged;
    }
    headerNameChange(tableData:any,columnsArray:any){
      let keyArray:any=[]
          keyArray = Object.keys(tableData[0]);  
      let fields:any=[] 
      columnsArray.forEach((element:any,index:any) => {
        if(keyArray.includes(element.value)){
          fields.push(element);
        }
      });
      return fields;
    }
    downloadCSV(csvData:any,fileName:String,fields?:any){
      let json2csvParser:any;
      if(fields){
        json2csvParser = new Parser({fields});
        // json2csvParser = new Parser({fields,excelStrings:true});
        // json2csvParser = new Parser({fields,withBOM:true});

      }else{
        // json2csvParser = new Parser({excelStrings:true});
        json2csvParser = new Parser();
      }
      console.log(json2csvParser)
      const csv = json2csvParser.parse(csvData);
      console.log(csv)
      let file = new Blob([csv], { type: 'text/plain;charset=utf-8' });
      fs.saveAs(file, fileName + '.csv');
      // NOTE:- You can also use new FILe() insted of creating Blob. but it might not work on IE and mozilla:- 
      // var file = new File([csv], "hello world.csv", {type: "text/plain;charset=utf-8"});
      // fs.saveAs(file);

      // fs.saveAs(file, fileName + '.xlsx');

    }
    showNotificaton(type:any,title:any,content:any){
        this._notifications.create(title, content, type)
    }


  resetPreviousUrl() {
    this.previousUrl='';
    this.currentUrl='';
  }
  getPreviousUrl() {
    return this.previousUrl;
  } 
}
