function makeAppConfig() {
    // let  env = 'dev';  //Change this for [ dev | uat | prod ] release
    let AppConfig = {
      // URL: "https://europe-backend-dot-whp-sp-portal-dev.ew.r.appspot.com",//QA     
      // serviceDocUrl:"https://wp-documentsqa.europeanappliances.com",//QA
      URL: "https://europe-backend-dot-whp-sp-portal-prod.ew.r.appspot.com",//For prod      
      serviceDocUrl:" https://wp-documents.europeanappliances.com",//prod
      apiKey: 'AIzaSyCCMDnRCxHGZslRo9q1v3edr4eHprnkoZ8',
      key:'AIzaSyCCMDnRCxHGZslRo9q1v3edr4eHprnkoZ8',
      language: 'EN',
      emailPattern: /^.+@.+\..+$/,
      };
      return AppConfig;
  }
  export const APPCONFIG = makeAppConfig();
