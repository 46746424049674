import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';
@Component({
  selector: 'app-new-claim',
  templateUrl: './new-claim.component.html',
  styleUrls: ['./new-claim.component.scss']
})
export class NewClaimComponent implements OnInit {

  constructor(private appService: AppService) { 
    this.appService.setTitle('New Claim');

  }

  ngOnInit(): void {
  }

}
