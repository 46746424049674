<main class="login">
   <div class="panel-left">
       <!-- <img src="../../assets/img/BekoEULogo.png" alt="logo"> -->
       <!-- <img src="https://storage.googleapis.com/whp-sp-portal-prod.appspot.com/images/LOGHI%20SERVICE_fondo_page-0001.jpg" alt="logo"> -->
       <img src="https://storage.googleapis.com/whp-sp-portal-prod.appspot.com/images/Service_logo_square.JPG" alt="logo">
       <form [formGroup]="loginForm" class="w-100" (submit)="login()">
       <article class="login-form">
           <h1>Login</h1>
        
           <mat-form-field appearance="fill">
            <mat-label>User Id</mat-label>
            <input matInput type="text"  formControlName="userName" [(ngModel)]="user.name" placeholder="Enter User Id" required>
            <mat-error>
                <mat-icon class="material-icons-round mr-1">
                warning
                </mat-icon> Please enter user id</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Password</mat-label>
            <input matInput type="text"  formControlName="password" [type]="hide ? 'password' : 'text'" [(ngModel)]="user.password" placeholder="Enter your password" required>
            <div mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </div>

              <mat-error>
                <mat-icon class="material-icons-round mr-1">
                warning
                </mat-icon> Please enter password</mat-error>
          </mat-form-field>
          <button mat-raised-button type="submit"color="primary"  >Login</button>

          <div class="auth-help" style="z-index: 100;">
              <!-- <a target="_blank" href="{{unlockAccountUrl}}">Unlock account</a> -->
              <a target="_blank" href="{{forgotPasswordUrl}}">Forgot password</a>
          </div>
       </article>
      </form>
       <img src="../../assets/img/shape.png" alt="shape" class="shape">
   </div>   
   <div class="panel-right">
     <article class="content">
         <h1>Welcome To Service Partner Portal</h1>
         <!-- <h2>Service Partner Portal</h2> -->
         <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> -->
     </article>
   </div>  
   
</main>

