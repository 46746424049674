import { Component, OnInit, ViewChild, HostListener,Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, share, shareReplay } from 'rxjs/operators';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { CommonService } from '../services/common.service';
import { AppService } from '../services/app.service';
import { DataShareService } from '../services/data-share.service';
@Component({
  selector: 'app-side-nav',
  templateUrl:'./side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  sidenav: boolean = false;
  header: boolean = false;
  showToggle: string | undefined;
  @Output() closeSideNav = new EventEmitter(); 
  userRole: string | null;
   

  constructor(private breakpointObserver: BreakpointObserver,
    private router: Router,private appService: AppService,private dataShareService:DataShareService
) { 
  this.userRole = this.dataShareService.getLocalStorage('role');
}
  
  onToggleClose() {
    this.closeSideNav.emit();
  }
  ngOnInit(): void {
  }
  isHandset$: Observable<boolean> = this.breakpointObserver
  .observe('(max-width: 992px)')
  .pipe(
    map((result) => result.matches),
    shareReplay(1)
  );
isLoginPage: boolean = true;
  

  claimTab(){
    console.log("aa")
    this.appService.setClaimManagementClicked('1')
  }
  consolidatedTab(){
    console.log("aa")
    // this.appService.setConsolidatedClicked('1')
  }

  soaplogTab(){
    console.log("soaploags")
    // this.appService.setConsolidatedClicked('1')
  }
}
