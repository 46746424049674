
export class StaticData {
  public static socClaimSheet ="SOC claim management table data";
  public static consolidatedClaimSheet ="Consolidated claims table data";
  public static consolidatedClaimDetailsSheet ="Consolidated claims item data list";

  public static interventionType = [
        {id:"WR",value:'Legal Warranty 1st Year'},
        {id:"WO",value:'Legal Warranty 2nd Year'},
        {id:"EW",value:'Extended Warranty'},
        {id:"TP",value:'Third Party Insurer'},
        {id:"OR",value:'Repair Paid by Trade Partners'},
        {id:"CT",value:'Internal Contracts'},
        {id:"C2",value:'Commercial Concession'},
        {id:"VR",value:'Vesta'},
        {id:"RR",value:'Recall Campaign'},
        {id:"PX",value:'Warranty after Recall Campaign Exchange'},
    ];

  public static interventionTypeObject={  
        "C2":	"Commercial Concession",
        "CT":	"Internal Contracts",
        "EW":	"Extended Warranty",
        "MR":	"Washing Machine Recall",
        "OR":	"Repair Paid by Trade Partners",
        "PX":	"Warranty after Recall Campaign Exchange",
        "RR":	"Recall Campaign",
        "TP":	"Third Party Insurer",
        "VR":	"Vesta",
        "WO":	"Legal Warranty 2nd Year",
        "WR":	"Legal Warranty 1st Year"
    }
    public static installationType = [
        {id:"FS",value:"Free Standing"},
        {id:"BI",value:"Built In"}
    ];
    public static serviceType = [
      {id:'001',value: "Standard Repair"},
      {id:'035', value:"Gas Safety Check"}
    ];
    public static serviceTypeObject = {
      '001':"Standard Repair",
      '035':"Gas Safety Check"
    };
    public static itemCategory = [
      {id:'001',value: "Type-1"},
      {id:'035', value:"Type-2"}
    ]
    public static documentType = [
      {id:'ZUKS',value: "SP Service Confirmation"},
      {id:'ZUKF', value:"SP Serv Conf Missing Sp"},
      {id:'ZUKT',value:"SP Rectification"}
    ]
    public static technicians = [
      {id:"tech1",value:"Technician-1"},
      {id:"tech2",value:"Technician-2"},
      {id:"tech3",value:"Technician-3"},
      {id:"tech4",value:"Technician-4"}
  ];
   public static SOCHeaderStatuses=[
     {id:'comp',value:"COMP"},
     {id:'flon',value:"FLON"},
     {id:'out',value:"OUT"},
     {id:'abort',value:"ABORT"},
     {id:'to_be_validated_automatically',value:"To be validated automatically"},
     {id:'closed_not_reimbursed',value:"Closed/Not Reimbursed"},
     {id:'to_be_validated_manually',value:"To be validated manually"},
     {id:'to_be_checked_by_sp',value:"To be checked by SP"},
     {id:'rejected_manually',value:"Rejected manually"},
     {id:'cancelled',value:"Cancelled"},
     {id:'accepted_automatically',value:"Accepted automatically"},
     {id:'accepted_manually',value:"Accepted manually"},
     {id:'closed_or_consolidated',value:"Closed/Consolidated"},
     {id:'invoice_posted',value:"Invoice Posted"}
   ];
   public static unlockAccountUrl = 'https://whr-self-registration-prod.appspot.com/#/unlock-account';
   public static forgotPasswordUrl = 'https://access.whirlpool.com/wpass/wpass/login';
   public static claimManagementTableCoulumn = [
    {'id':'socId',value:'SOC ID'},
    {'id':'vendorName',value:'Vendor'},
    {'id':'technician',value:'Technician'},
    {'id':'docType',value:'Document Type'},
    {'id':'status',value:'Status'},
    {'id':'docNumber',value:'Document Number'},
    {'id':'creationDate',value:'Creation Date'},
    {'id':'appointmentDate',value:'Appointment Date'},
    {'id':'callDate',value:'Call Date'},
    {'id':'accountingIndicator',value:'Accounting Indicator'},
    {'id':'serviceType',value:'Service Type'},
    {'id':'externalReference',value:'External Reference'},
    {'id':'customerNumber',value:'Customer Number'},

   ];
// public static claimManagementTablecolumsName =  [
//     'SOC ID',
//     'Service Partner name',
//     'customer name',
//     'Customer name','Booking date',
//     'Visit date',
//     'Closure date',
//     'Intervention type',
//     'Status',
//     'Total amount',
//     'Detail'
//    ];
//    public static claimManagementTableCoulumn = ['soc_id','sp_name','user_name','customer_name','booking_date','visit_date','clouser_date','invention_type','statue','total_amount','detail',]
public static claimManagementTableData = [
  {soc_id:'6220292690',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'6220292690',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'6220292611',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666863',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666865',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666866',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666867',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666869',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666800',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  {soc_id:'1611666862',vendor:'18764346',technician:'Technician-1',doc_type:'Rectification',status:'To be validated automatically',doc_number:'xxx112',creation_date:'11/08/20',appointment_date:'18/08/20',callDate:'28/08/20',accounting_indicator:'Legal Warranty 1st Year',service_type:'Standard Repair',external_reference:'N/A',customer_number:'xx111'},
  ];

  public static consolidatedClaimTableData = [
    {docNo:'1611666861',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666862',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666868',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666863',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666864',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666865',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666866',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666862',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666861',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666861',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666861',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666861',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666861',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666861',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666861',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666861',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666861',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
    {docNo:'1611666861',vendor_id:'18764346',vendor_name:'vendor-1',claim_creation_date:'01-01-2019',claim_status:'Invoice Posted',total_amount:'2.75.30',currency:'EUR'},
  ];


  public static consolidatedClaimTableCoulumn = [
    {'id':'docNo',value:'Document Number'},
    {'id':'vendorId',value:'Vendor ID'},
    {'id':'vendorName',value:'Vendor Name'},
    {'id':'claimCreationDate',value:'Creation Date'},
    {'id':'claimStatus',value:'Staus'},
    {'id':'totalAmount',value:'Total Amount'},
    {'id':'currency',value:'Currency'},
    {'id':'action',value:'Action'}
   ];
   public static notesTableCoulumn = [
    // {'id':'noteTextType',value:'Text Type'},
    {'id':'noteTdText',value:'Text Description'},
    {'id':'noteText',value:'Notes'},
    // {'id':'action',value:'Action'},
   ];
  // public static notesTableData=[
  //   {text_type:"History",text:'xxxx this is text fields'},
  //   {text_type:"History",text:'xxxx this is text fields'},
  //   {text_type:"History",text:'xxxx this is text fields'},
  //   {text_type:"History",text:'xxxx this is text fields'}
  // ]

  public static transactionTableCoulumn = [
    {'id':'transactionID',value:'Transaction ID'},
    {'id':'transactionDescription',value:'Transaction Description'},
    {'id':'transactionCreationDate',value:'Creation Date'},
    // {'id':'status',value:'Status'},
  ];
  // public static transactionTableData=[
  //   {transation_id:"xxxxx1114",transation_desc:'xxxx this is desc',creation_date:'05-05-2019',status:'Open'},
  //   {transation_id:"xxxxx1113",transation_desc:'xxxx this is desc',creation_date:'05-05-2019',status:'Open'},
  //   {transation_id:"xxxxx1112",transation_desc:'xxxx this is desc',creation_date:'05-05-2019',status:'Open'},
  //   {transation_id:"xxxxx1115",transation_desc:'xxxx this is desc',creation_date:'05-05-2019',status:'Open'},
  //   {transation_id:"xxxxx1116",transation_desc:'xxxx this is desc',creation_date:'05-05-2019',status:'Open'}
  // ]


  public static partiesTableCoulumn = [
    {'id':'functionId',value:'Function ID'},
    {'id':'partnerName',value:'Function Name'},
    // {'id':'firstName',value:'First Name'},
    // {'id':'lastName',value:'Last Name'},
    {'id':'functionDescription',value:'Function Description'},
  ];
  // public static partiesTableData=[
  //   {function_id:"xxxxx1111",function_name:'fun name',function_desc:'xxxx this is desc'},
  //   {function_id:"xxxxx1112",function_name:'fun name',function_desc:'xxxx this is desc'},
  //   {function_id:"xxxxx1113",function_name:'fun name',function_desc:'xxxx this is desc'},
  //   {function_id:"xxxxx1114",function_name:'fun name',function_desc:'xxxx this is desc'},
  // ]

  public static itemTableCoulumn = [
    {'id':'itemNumber',value:'Item Number'},
    {'id':'productId',value:'Product ID'},
    {'id':'productDescription',value:'Product Description'},
    {'id':'itemQuantity',value:'Quantity'},
    {'id':'itemUnit',value:'Unit'},
    {'id':'itemPrice',value:'Price'},
    // {'id':'currency',value:'Currency'},
    {'id':'itemCategory',value:'Item Category'},
    {'id':'statusText',value:'Status'},
    {'id':'action',value:'Action'},
  ];
  // public static itemTableData=[
  //   {item_no:"xxxxx1111",product_id:'xx1222',froduct_desc:'xxxx this is desc',quantity:'5',unit:'NR',price:'20.6',currency:'INR',item_category:'ZUKD',status:'completed'},
  //   {item_no:"xxxxx1112",product_id:'xx1222',froduct_desc:'xxxx this is desc',quantity:'5',unit:'NR',price:'20.6',currency:'INR',item_category:'Service item',status:'completed'},
  //   {item_no:"xxxxx1113",product_id:'xx1222',froduct_desc:'xxxx this is desc',quantity:'5',unit:'NR',price:'20.6',currency:'INR',item_category:'ZUKD',status:'completed'},
  //   {item_no:"xxxxx1114",product_id:'xx1222',froduct_desc:'xxxx this is desc',quantity:'5',unit:'NR',price:'20.6',currency:'INR',item_category:'Service item',status:'completed'},
  //   {item_no:"xxxxx1115",product_id:'xx1222',froduct_desc:'xxxx this is desc',quantity:'5',unit:'NR',price:'20.6',currency:'INR',item_category:'Service item',status:'completed'},
  //   {item_no:"xxxxx1112",product_id:'xx1222',froduct_desc:'xxxx this is desc',quantity:'5',unit:'NR',price:'20.6',currency:'INR',item_category:'Service item',status:'completed'},
  //   {item_no:"xxxxx1113",product_id:'xx1222',froduct_desc:'xxxx this is desc',quantity:'5',unit:'NR',price:'20.6',currency:'INR',item_category:'ZUKD',status:'completed'},
  //   {item_no:"xxxxx1114",product_id:'xx1222',froduct_desc:'xxxx this is desc',quantity:'5',unit:'NR',price:'20.6',currency:'INR',item_category:'Service item',status:'completed'},
  //   {item_no:"xxxxx1115",product_id:'xx1222',froduct_desc:'xxxx this is desc',quantity:'5',unit:'NR',price:'20.6',currency:'INR',item_category:'Service item',status:'completed'}
  // ]
  public static textType=[
    {id:'type1',value:'Type-1'},
    {id:'type2',value:'Type-2'},
    {id:'type3',value:'Type-3'}
  ]

  public static consClaimSocTableColumn = [
    {'id':'docNumber',value:'Document Number'},
    {'id':'docDescription',value:'Document Description'},
    {'id':'externalReference',value:'External Reference'},
    {'id':'technician',value:'Technician'},
    {'id':'accountingIndicator',value:'Accounting Indicator'},
    {'id':'serviceType',value:'Service Type'},
    // {'id':'status',value:'Status'},
    {'id':'totalAmount',value:'Total Amount'},
    {'id':'creditMemo',value:'Credit Memo'}
  ];
  public static consClaimSocTableData=[
    {docNumber:"xxxxx1111",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1112",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1113",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1114",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1115",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1116",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1117",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1111",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1111",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1111",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1111",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1111",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1111",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1111",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1111",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1111",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1111",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'},
    {docNumber:"xxxxx1111",docDescription:'xxxx this is doc desc',externalReference:'xxxx this is external ref.',technician:'technician-1',accountingIndicator:'acc-ind-1', serviceType:'Srvice type-1',status:'Status-1',totalAmount:'122',creditMemo:'memo-1'}
  ];
  public static documentNumbers = [
    {'id':'doc_number_1',value:'Document Number-1'},
    {'id':'doc_number_2',value:'Document Number-2'},
    {'id':'doc_number_3',value:'Document Number-3'},
    {'id':'doc_number_4',value:'Document Number-4'},
  ];
  public static externalReferences = [
    {'id': 'ect_ref_1', value:'External reference-1'},
    {'id': 'ect_ref_2', value:'External reference-2'},
    {'id': 'ect_ref_3', value:'External reference-3'}
  ];
  public static creaditMemo = [
    {'id':'memo_1',value:'Memo-1'},
    {'id':'memo_2',value:'Memo-2'},
    {'id':'memo_3',value:'Memo-3'},
  ]
}




