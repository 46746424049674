import { Component, OnInit,Input } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import { Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-chip-input',
  templateUrl: './chip-input.component.html',
  styleUrls: ['./chip-input.component.scss']
})
export class ChipInputComponent implements OnInit {
    visible: boolean = true;
    selectable: boolean = true;
    removable: boolean = true;
    addOnBlur: boolean = true;
    separatorKeysCodes = [ENTER, COMMA];
  @Input() placeholder = '';
  @Output() addRemoveChipEvent = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }
  keywords:any = [];
  
  add(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;
    // Add our keyword
    if ((value || '').trim()) {
      this.keywords.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.addRemoveChipEvent.emit(this.keywords);
}

remove(keyword:any): void {
  let index = this.keywords.indexOf(keyword);
  if (index >= 0) {
    this.keywords.splice(index, 1);
  }
  this.addRemoveChipEvent.emit(this.keywords);
}

}
