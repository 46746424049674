import { HttpInterceptor, HttpRequest, HttpHandler,HttpResponse,HttpErrorResponse, HttpUserEvent, HttpEvent } from "@angular/common/http";
import { Observable, throwError, } from "rxjs";
import { HttpService } from "./http.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { EMPTY } from 'rxjs';
import { retry, catchError,tap } from 'rxjs/operators';
import { AlertService } from '../alert-dialog/alert.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ErrorAlertDialogComponent } from "../alert-dialog/alert-dialog.component";
@Injectable({
    providedIn: 'root'
  })
  
export class AuthInterceptor implements HttpInterceptor {
//   dialogRef:MatDialogRef;
dialogRef:any;
  constructor( private router :Router, private alertService: AlertService,private matDialog: MatDialog){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // let accessToken = localStorage.getItem("auth-token");
        // if(accessToken)
        // {
        //     request = request.clone({
        //     setHeaders: {
        //         Authorization: `Bearer ${accessToken}`
        //     }
        //     });
        // }
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
                this.openErrorDialogBox('Session Expired, Please Login Again.');
                setTimeout(()=>{
                  this.closeErrorDialogue();
                 },3000)   
            }
          }
        }));
      }
  
      openErrorDialogBox(message:any) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = message;
        if(this.dialogRef == null){
          //do the thing
          this.dialogRef = this.matDialog.open(ErrorAlertDialogComponent, dialogConfig);
        }

        this.dialogRef.afterClosed().subscribe(() => {
          this.dialogRef=null;
          localStorage.clear()
         this.router.navigate(['/login']);
      });
      }
      closeErrorDialogue(){
        if(this.dialogRef){
          this.dialogRef.close();
        }
        localStorage.clear()
        this.router.navigate(['/login']);
          this.dialogRef=null;
      }

}

