import { Injectable } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogComponent,ErrorAlertDialogComponent} from '../alert-dialog/alert-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  message : string | undefined;
  dialogRef:any;
  constructor(private matDialog: MatDialog) { 
  }

refreshDialogref(){
  this.dialogRef = null;
}
  openDialogBox(message:any) {
    this.dialogRef=null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = message;
    dialogConfig.width = '317px';
    this.dialogRef = this.matDialog.open(AlertDialogComponent, dialogConfig);
  }
  
  openErrorDialogBox(message:any) {
    this.dialogRef=null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = message;
    dialogConfig.width = '317px';
    // dialogConfig.minHeight= 'calc(100vh - 528px)';
    // dialogConfig.height = '259px';

    // dialogConfig.maxWidth='calc(100vw - 65%)';
    this.dialogRef = this.matDialog.open(ErrorAlertDialogComponent, dialogConfig);

  }
closeErrorAlert(){
  // let dialogRef:MatDialogRef<ErrorAlertDialogComponent>;
  // this.errotDialogRef.close();
}
}
