import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators,FormGroup } from '@angular/forms';
import { HttpService } from '../services/http.service';
import {StaticData} from '../shared/staticData';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AlertService } from '../alert-dialog/alert.service';
import { DataShareService } from '../services/data-share.service';
import { CommonService } from '../services/common.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class LoginComponent implements OnInit {


  hide = true;
  user: any = {};
  unlockAccountUrl:string = StaticData.unlockAccountUrl;
  forgotPasswordUrl:string = StaticData.forgotPasswordUrl;
  loader: boolean = false;
  loginForm:any
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  constructor(private httpService: HttpService, private router: Router,private _snackBar: MatSnackBar,private loaderService: NgxUiLoaderService,
    private alertService: AlertService, private dataShareService: DataShareService,private commonService:CommonService) {
    
    if (this.dataShareService.getLocalStorage('userToken') != null)
    {
      this.router.navigate(['home/claim-management']);
    }
 
    this.loginForm = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    let url=this.commonService.getPreviousUrl()
  }

  login() {
    this.loader = true;
    
    let postData = {
      "userId": this.user.name, "password": this.user.password
    };
    if (this.user && (!this.user.name || !this.user.password)) {
      this.loaderService.stop();
      this.alertService.openErrorDialogBox('User Id or password is required!')


      return;
    } 
    this.loaderService.start()
    this.httpService.loginRequest('loginOperationApi/v1/ldap_login', postData).subscribe(
      res => {
        this.loaderService.stop();
         let hasAccess=true; 
         let roles:any=[];
        /**For New login response */
        if(res.role && res.role.length>0){
          for(let i=0;i<res.role.length;i++){
            if(res.role[i].code=='SP'){
              if(!res.customerCode){
                hasAccess = false;
                break
              }
            }
            roles=res.role[i].code;
          }
          if(hasAccess){
            this.dataShareService.setLocalStorage('userName',res.userName);
            this.dataShareService.setLocalStorage('userId',res.userId);
            this.dataShareService.setLocalStorage('userToken',res.accessToken);
            this.dataShareService.setLocalStorage('language',res.language);
            this.dataShareService.setLocalStorage('role',roles);
            this.dataShareService.setLocalStorage('customerNumber',res.customerCode);
            this.router.navigate(['/home/claim-management']);
            if(roles=='SP'){    
              this.retrieveConsolidatedClaimsList(res)
            }
          }
          else{
            this.alertService.openErrorDialogBox('You do not have access!');
          }
        }else{
          this.alertService.openErrorDialogBox('You do not have access!')
        }
       },
      err => {
        this.loaderService.stop();
        var error = err['error']
        switch (error.errorMessage) {
          case "INVLD_CRDNTLS":
            this.alertService.openErrorDialogBox('Invalid username or password!')

            break;
          case "WPASS_LCK":
            this.alertService.openErrorDialogBox('Please unlock your Account!')

            break;
          case "WPASS_DWN":
            this.alertService.openErrorDialogBox('WPASS Service temporarily down. Please try again later!')

            break;
          case "INVLD_PASS_CRIT":
            this.alertService.openErrorDialogBox('Password does not match criteria!')

            break;
          case "WPASS_USR_INACT":
            this.alertService.openErrorDialogBox('WPASS User not Active!')

            break;
          case "WPASS_USR_INACT":
            this.alertService.openErrorDialogBox('Could not connect to WPASS Service!')

            break;
         default:
            this.alertService.openErrorDialogBox('Something went wrong. Please try again or contact admin!')
            break;
        }
      }
    );
  }


  retriveSocList(res:any){
    let data:any={
      "accountingIndicator": [],
      "appointmentDateFrom": "",
      "callDateFrom": "",
      "creationDateFrom": "",
      "appointmentDateTo": "",
      "callDateTo": "",
      "creationDateTo": "",
      "customer": [],
      "documentStatus": [],
      "language": "en",
      "searchType": "01",
      "serviceType": [],
      "soId": [],
      "socid": [],
      "spCustomerCode": [this.dataShareService.getLocalStorage('customerNumber')],/**Also known as customerId */
      "technician": [],
      "userId": this.dataShareService.getLocalStorage('userId'),
      "userProfile": this.dataShareService.getLocalStorage('role'),
      "toBeUpdated": false,
      "socDbId":""
     }
    this.dataShareService.setLocalStorage('retriveSocListRequestDto',JSON.stringify(data));
    this.httpService.post('claimApi/v1/retrieveSocList',data).subscribe((data)=>{
     },error=>{
  
     })
  }
  retrieveConsolidatedClaimsList(res:any){
  
     let data= {
       "languageKey":"EN",
        "userID":res.userId,
        "userProfile":this.dataShareService.getLocalStorage('role'),
        "vendorCode":[localStorage.getItem('customerNumber')],
        "consolidatedClaimID":[],
        "consolidatedClaimDateFrom":"",
        "consolidatedClaimDateTo":"",
        "consolidatedClaimStatus":["E0004","E0003"],
        "socID":[],
        "soID":[]
      }
       
    this.httpService.post('claimApi/v1/retrieveConsolidatedClaimsList',data).subscribe((data)=>{
     },error=>{
  
     })
  }
}
