
import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { EMPTY, Observable, of, TimeoutError } from 'rxjs';
import { timeout, catchError, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorAlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { NgxUiLoaderService } from "ngx-ui-loader";

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
  providedIn: 'root'
})
export class RequestTimeoutHttpInterceptor implements HttpInterceptor {
    dialogRef:any;

  constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private matDialog: MatDialog,private loaderService: NgxUiLoaderService,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(request).pipe(timeout(this.defaultTimeout),tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
    }, (err: any) => {

        if (err instanceof TimeoutError){
            this.loaderService.stopAll();
            this.openErrorDialogBox('Some error Occurred.\n Please Try Again or Contact To Administrator.');
        }
    }));
  }
  openErrorDialogBox(message:any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = message;
    if(this.dialogRef == null){
      //do the thing
      this.dialogRef = this.matDialog.open(ErrorAlertDialogComponent, dialogConfig);
    }
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef=null;

  });
  }
  closeErrorDialogue(){
    if(this.dialogRef){
      this.dialogRef.close();
    }
      this.dialogRef=null;
  }
}