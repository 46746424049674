import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { AppService } from '../services/app.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { StaticData } from '../shared/staticData';
import { AlertService } from '../alert-dialog/alert.service';
import { ExportExcelService } from '../services/export-excel.service';
import * as fs from 'file-saver';
import { CommonService } from '../services/common.service';
const { Parser } = require('json2csv');
import * as printJS from 'print-js';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpService } from '../services/http.service';
import { ServiceDocService } from '../services/service-doc.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { DataShareService } from '../services/data-share.service';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { ArrayDataSource } from '@angular/cdk/collections';
import * as dayjs from 'dayjs';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
declare var $: any;
declare var require: any;
const FileSaver = require('file-saver');
@Component({
  selector: 'app-consolidated-claim',
  templateUrl: './consolidated-claim.component.html',
  styleUrls: ['./consolidated-claim.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    // {
    //   provide: DateAdapter,
    //   useClass: MomentDateAdapter,
    //   deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    // },
    // {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ConsolidatedClaimComponent implements OnInit, AfterViewInit {
  value = 'Clear me';
  @ViewChild('select')
  select!: MatSelect;
  allSelected = false;
  pageType = 'consolidated_claim';
  @ViewChild(MatSort) sort: MatSort | any;
  columnFilterControl = new FormControl([]);
  displayedColumns: any = [];
  dataSource: any;
  columnsOption: any = [];
  columnsToDisplay: any = [];
  advancedSearchForm: any;
  interventionType: any;
  installationType: any;
  mainFilter = false;
  comingFromSocDetail = false;
  consolidatedClicked = '0';
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  dropdownSettings: any;
  spDropdownSettings: any;
  columnMappingsData: any = {};
  dmDropdownSettings: any = {};
  technicianDropdownSettings: any = {};
  userId: any;
  userRole: any;
  userToken: any;
  serviceProviderDetals: any;
  pdfSrc: any;
  downloadUrl: any = '';
  toBeDownloaded: boolean = false;
  // *****For chiplist**********
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  separatorKeysCodes = [ENTER, COMMA];
  socIds: any = [];
  soIds: any = [];
  consolidatedNumbers: any = [];
  customerNumber: any;
  date = new FormControl(moment().subtract(1, 'months'));
  isTableDataSet: boolean = false;
  @ViewChild('bookingdateRangeInput') bookingdateRangeInput: ElementRef | any;
  @ViewChild('consolidatedDatePicker') consolidatedDatePicker: ElementRef | any;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  };
  maxDate: dayjs.Dayjs = dayjs();
  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private serviceDocService: ServiceDocService,
    public commonService: CommonService,
    private sanitizer: DomSanitizer,
    private loaderService: NgxUiLoaderService,
    private dataShareService: DataShareService,
    private alertService: AlertService
  ) {
    this.appService.setTitle('Consolidated Claim');
    // this.interventionType = StaticData.interventionType;
    // this.installationType = StaticData.installationType;
    // this.status = StaticData.SOCHeaderStatuses;
    this.userId = this.dataShareService.getLocalStorage('userId');
    this.userRole = this.dataShareService.getLocalStorage('role');
    this.userToken = this.dataShareService.getLocalStorage('userToken');
    this.customerNumber =
      this.dataShareService.getLocalStorage('customerNumber');
    this.dataSource = new MatTableDataSource();
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const todaydate = today.getDate();

    this.advancedSearchForm = this.formBuilder.group({
      cons_vendor: [
        this.dataShareService.getFilterConsolidateRequestParameter()
          ? this.dataShareService.getFilterConsolidateRequestParameter()
              .cons_vendor
          : '',
      ],
      cons_number: [
        this.dataShareService.getFilterConsolidateRequestParameter()
          ? this.dataShareService.getFilterConsolidateRequestParameter()
              .cons_number
          : '',
      ],

      cons_start_date: [
        this.dataShareService.getFilterConsolidateRequestParameter()
          ? this.dataShareService.getFilterConsolidateRequestParameter()
              .cons_start_date
          : '',
      ], //new Date(year, month-10, 1)
      cons_end_date: [
        this.dataShareService.getFilterConsolidateRequestParameter()
          ? this.dataShareService.getFilterConsolidateRequestParameter()
              .cons_end_date
          : '',
      ], //new Date(year, month, todaydate)
      cons_status: [
        this.dataShareService.getFilterConsolidateRequestParameter()
          ? this.dataShareService.getFilterConsolidateRequestParameter()
              .cons_status
          : '',
      ],
      so_id: [
        this.dataShareService.getFilterConsolidateRequestParameter()
          ? this.dataShareService.getFilterConsolidateRequestParameter().so_id
          : '',
      ],
      socId: [
        this.dataShareService.getFilterConsolidateRequestParameter()
          ? this.dataShareService.getFilterConsolidateRequestParameter().socId
          : '',
      ],
      serviceProvider: [
        this.dataShareService.getFilterConsolidateRequestParameter()
          ? this.dataShareService.getFilterConsolidateRequestParameter()
              .serviceProvider
          : '',
      ],
      districtsManagers: [
        this.dataShareService.getFilterConsolidateRequestParameter()
          ? this.dataShareService.getFilterConsolidateRequestParameter()
              .districtsManagers
          : '',
      ],
      servicePartners: [
        this.dataShareService.getFilterConsolidateRequestParameter()
          ? this.dataShareService.getFilterConsolidateRequestParameter()
              .servicePartners
          : '',
        [Validators.required],
      ],
      technicians: [
        this.dataShareService.getFilterConsolidateRequestParameter()
          ? this.dataShareService.getFilterConsolidateRequestParameter()
              .technicians
          : '',
      ],

      /** ***for ngxDtepicker** */
      // consolidatedDate: [this.dataShareService.getFilterConsolidateRequestParameter() && this.dataShareService.getFilterConsolidateRequestParameter().consolidatedDate.startDate?{start:this.dataShareService.getFilterConsolidateRequestParameter().consolidatedDate.startDate,end:this.dataShareService.getFilterConsolidateRequestParameter().consolidatedDate.endDate}:''],
    });

    this.selectedDMs =
      this.dataShareService.getFilterConsolidateRequestParameter()
        ? this.dataShareService.getFilterConsolidateRequestParameter()
            .districtsManagers
        : [];
    this.selectedSp =
      this.dataShareService.getFilterConsolidateRequestParameter()
        ? this.dataShareService.getFilterConsolidateRequestParameter()
            .servicePartners
        : [];
    this.selectedTechnicians =
      this.dataShareService.getFilterConsolidateRequestParameter()
        ? this.dataShareService.getFilterConsolidateRequestParameter()
            .technicians
        : [];
    this.appService
      .getConsolidatedClicked()
      .subscribe(
        (consolidatedClicked: any) =>
          (this.consolidatedClicked = consolidatedClicked)
      );
    this.getRoleHierarchyMapping();
  }

  chosenYearHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }
  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.dmDropdownSettings = {
      singleSelection: false,
      idField: 'dmId',
      textField: 'dmName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.spDropdownSettings = {
      singleSelection: false,
      idField: 'spId',
      textField: 'spName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText:
        'Please Select Atleast One District Manager',
    };
    this.technicianDropdownSettings = {
      singleSelection: false,
      idField: 'technicianId',
      textField: 'technicianName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText:
        'Please select Atleast One Service Partner',
    };
    if (
      this.userRole != 'SP' &&
      this.dataShareService.getConsolidatedMainFilterFlag()
    ) {
      this.mainFilter = true;
    } else {
      this.mainFilter = false;
    }

    this.getUserColumnMaping();
    // this.retrieveConsolidatedClaimsList();
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.setPaginator();
    this.setDefaultMaxDate();
  }
  setDefaultMaxDate() {
    setTimeout(() => {
      this.bookingdateRangeInput.max = new Date();
      this.bookingdateRangeInput.min = '';
    });
  }
  setPaginator() {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    });
  }
  searchAgain() {
    this.mainFilter = true;
    this.setDefaultMaxDate();
  }
  // *****Column filter start******
  _tempColumnsToDisplay: any = [];
  addColumn() {
    this.columnFilterControl.value.forEach((element: any) => {
      if (!this._tempColumnsToDisplay.includes(element)) {
        if (element == 'consolidatedId') {
          this._tempColumnsToDisplay.unshift(element);
        } else {
          this._tempColumnsToDisplay.push(element);
        }
        if (!this._tempColumnsToDisplay.includes('consolidatedId')) {
          this._tempColumnsToDisplay.unshift('consolidatedId');
        }
      }
    });
    // setTimeout(()=>{
    if (!this._tempColumnsToDisplay.includes('action')) {
      this._tempColumnsToDisplay.push('action');
    } else {
      let index = this._tempColumnsToDisplay.indexOf('action');
      this._tempColumnsToDisplay.splice(index, 1);
      this._tempColumnsToDisplay.push('action');
    }
    // })
  }
  removeColumn() {
    if (this._tempColumnsToDisplay.length) {
      let columnsToDisplay = JSON.parse(
        JSON.stringify(this._tempColumnsToDisplay)
      );
      this._tempColumnsToDisplay.forEach((element: any, index: any) => {
        if (!this.columnFilterControl.value.includes(element)) {
          let i = columnsToDisplay.indexOf(element);
          if (i !== -1) {
            columnsToDisplay.splice(i, 1);
          }
        }
        if (this.columnFilterControl.value.length == 0) {
          this._tempColumnsToDisplay = [];
        }
      });
      this._tempColumnsToDisplay = columnsToDisplay;

      if (!this._tempColumnsToDisplay.includes('consolidatedId')) {
        this._tempColumnsToDisplay.unshift('consolidatedId');
      }
      if (!this._tempColumnsToDisplay.includes('action')) {
        this._tempColumnsToDisplay.push('action');
      } else {
        let index = this._tempColumnsToDisplay.indexOf('action');
        this._tempColumnsToDisplay.splice(index, 1);
        this._tempColumnsToDisplay.push('action');
      }
    }
  }
  initialSelect() {
    this.select.options.forEach((item: MatOption) => {
      if (this._tempColumnsToDisplay.includes(item.value)) {
        item.select();
      }
    });
    if (this._tempColumnsToDisplay.length == this.displayedColumns.length) {
      this.allSelected = true;
    }
    this.onAdvancedFilterModelClosed();
  }
  toggleAllSelection() {
    this._tempColumnsToDisplay = [];
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
      this.addColumn();
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
      this._tempColumnsToDisplay = ['consolidatedId', 'action'];
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    this.addColumn();
    this.removeColumn();
  }
  onColumnRemoved(col: string) {
    const cols = this.columnFilterControl.value as string[];
    this.removeFirst(cols, col);
    this.columnFilterControl.setValue(cols); // To trigger change detection
    this.allSelected = false;
    this.removeColumn();
  }
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
  // *****Column filter End******
  exportToExcel() {
    let consolidatedClaimTableCoulumn = [
      { label: 'Consolidated Id', value: 'consolidatedId' },
      { label: 'Vendor Id', value: 'vendorId' },
      { label: 'Vendor Name', value: 'vendorName' },
      { label: 'Creation Date', value: 'claimCreationDate' },
      { label: 'Status', value: 'claimStatus' },
      { label: 'Total Amount', value: 'totalAmount' },
      { label: 'Action', value: 'action' },
    ];
    if (this.userRole == 'SP') {
      for (var i = consolidatedClaimTableCoulumn.length - 1; i >= 0; i--) {
        if (
          consolidatedClaimTableCoulumn[i].value === 'vendorId' ||
          consolidatedClaimTableCoulumn[i].value === 'claimStatus'
        ) {
          consolidatedClaimTableCoulumn.splice(i, 1);
        }
      }
    }
    let paged = this.commonService.prepareTableDataForCsv(
      this.dataSource,
      this.columnsToDisplay,
      this.columnsOption,
      this.paginator
    );
    let fields = this.commonService.headerNameChange(
      paged,
      consolidatedClaimTableCoulumn
    );
    this.commonService.downloadCSV(
      paged,
      StaticData.consolidatedClaimSheet,
      fields
    );
  }
  mainFilterSearch() {
    this.retrieveConsolidatedClaimsList();
    setTimeout(() => {
      this.setDefaultMaxDate();
    });
  }
  cancelSearchAgain() {
    this.mainFilter = false;
    setTimeout(() => {
      this.dataSource.sort = this.sort;
    }, 3000);
    this.setPaginator();
    this.setDefaultMaxDate();
  }
  resetAdvanceFilterForm() {
    this.advancedSearchForm.reset();
    this.consolidatedDatePicker.nativeElement.value = '';
    this.socIds = [];
    this.soIds = [];
    this.consolidatedNumbers = [];
    this.setDefaultMaxDate();
  }
  resetMainFilterForm() {
    this.advancedSearchForm.reset();
    this.consolidatedDatePicker.nativeElement.value = '';
    this.socIds = [];
    this.soIds = [];
    this.consolidatedNumbers = [];
    this.selectedSp = [];
    this.setDefaultMaxDate();
  }
  getUserColumnMaping() {
    this.loaderService.start();
    this.httpService
      .get('columnPreferenceApi/v1/getColumnMappings?pageType=' + this.pageType)
      .subscribe(
        (data: any) => {
          if (this.userRole == 'SP') {
            delete data.columnMappings.claimStatus;
            delete data.columnMappings.vendorId;
          }
          this.columnMappingsData = data.columnMappings;
          for (var element in data.columnMappings) {
            if (data.columnMappings.hasOwnProperty(element)) {
              let obj: any = {};
              obj['id'] = element;
              obj['value'] = data.columnMappings[element];
              if (element == 'consolidatedId') {
                this.displayedColumns.splice(0, 0, obj);
              } else {
                this.displayedColumns.push(obj);
              }
            }
          }
          this.displayedColumns.push({ id: 'action', value: 'Action' });
          this.columnsOption = this.displayedColumns;
          this.getUserColumnPref();
        },
        (error) => {
          this.loaderService.stop();
        }
      );
  }
  getUserColumnPref() {
    this.httpService
      .get(
        'columnPreferenceApi/v1/getUserColumnPreferences?userId=' + this.userId
      )
      .subscribe(
        (data: any) => {
          this.loaderService.stop();
          let hasPageTypeData: boolean = false;
          if (data.userPreferenceActive) {
            data.columnPreferencesList.forEach((list: any) => {
              if (list.pageType == this.pageType) {
                hasPageTypeData = true;
                if (this.userRole == 'SP') {
                }
                if (
                  list.preferredColumnList.length == 0 ||
                  list.preferredColumnList[0] == ''
                ) {
                  this.displayedColumns.forEach((element: any) => {
                    if (element.id != 'action') {
                      this.columnsToDisplay.push(element.id);
                    }
                  });
                } else {
                  list.preferredColumnList.forEach((element: any) => {
                    if (element != 'action') {
                      if (
                        this.userRole == 'SP' &&
                        element != 'vendorId' &&
                        element != 'claimStatus'
                      ) {
                        /** do not show vendorID and status for SP */
                        if (element == 'consolidatedId') {
                          this.columnsToDisplay.unshift(element);
                        } else {
                          this.columnsToDisplay.push(element);
                        }
                      } else if (this.userRole != 'SP') {
                        /** allow all paramete for non sp role */
                        if (element == 'consolidatedId') {
                          this.columnsToDisplay.unshift(element);
                        } else {
                          this.columnsToDisplay.push(element);
                        }
                      }
                    }
                  });
                }
              }
            });
            if (!hasPageTypeData) {
              this.displayedColumns.forEach((element: any) => {
                if (element.id != 'action') {
                  this.columnsToDisplay.push(element.id);
                }
              });
            }
          } else {
            this.displayedColumns.forEach((element: any) => {
              if (element.id != 'action') {
                this.columnsToDisplay.push(element.id);
              }
            });
          }
          this.columnsToDisplay.push('action');
          if (this.userRole == 'SP') {
            //check if user is sp then directly call filterConsolidateClaim API.
            this.filterConsolidateClaim();
          } else if (!this.dataShareService.getConsolidatedMainFilterFlag()) {
            //check if user is not sp and already done main filterring then directly call filterConsolidateClaim API.
            this.filterConsolidateClaim();
          } else {
            //check if user is not sp and  user not filtered with  mainFilter then show mainFilter
            this.loaderService.stop();
          }
          this._tempColumnsToDisplay = JSON.parse(
            JSON.stringify(this.columnsToDisplay)
          );
        },
        (error) => {
          this.loaderService.stop();
        }
      );
  }
  setUserColumnPref() {
    let paramdata = '';
    this.columnsToDisplay = this._tempColumnsToDisplay;
    this.columnsToDisplay.forEach((element: any) => {
      paramdata = paramdata + 'columnList=' + element + '&';
    });
    paramdata = paramdata + 'pageType=' + this.pageType + '&';
    paramdata = paramdata + 'userId=' + this.userId;
    this.httpService
      .post('columnPreferenceApi/v1/setUserColumnPreferences?' + paramdata)
      .subscribe(
        (data: any) => {
          this.loaderService.stop();
          if (!this.columnsToDisplay.includes('action')) {
            this.columnsToDisplay.push('action');
          }
        },
        (error) => {
          this.loaderService.stop();
        }
      );
  }
  onAdvancedFilterModelClosed() {
    let self = this;
    $('#exampleModalCenter').on('click', function (e: any) {});
  }
  openPrintModel(consolidatedId: any) {
    this.loaderService.start();

    $('#printModel').modal('hide');
    let data: any;
    let code = '';
    let message = '';
    let docUploadDate = '';
    let maxDocUploadDate = '';
    let fileURL = '';
    let requestJson = {
      transactionId: consolidatedId,
    };

    this.serviceDocService
      .post('wiseservice/document/downloadDocument', requestJson)
      .subscribe(
        (response) => {
          this.loaderService.stop();
          data = response.data;
          code = response.code;
          message = response.message;
          if (code == '200' && data != undefined && data.length > 0) {
            if (data.length > 1) {
              maxDocUploadDate = data[0].documentUploadDate
                ? moment(data[0].documentUploadDate.toString()).format(
                    'YYYY-MM-DD,h:mm:ss'
                  )
                : '';
              data.forEach((element: any) => {
                docUploadDate = element.documentUploadDate
                  ? moment(element.documentUploadDate.toString()).format(
                      'YYYY-MM-DD,h:mm:ss'
                    )
                  : '';
                if (docUploadDate > maxDocUploadDate) {
                  maxDocUploadDate = docUploadDate;
                  this.pdfSrc = element.urlMediaLink;
                }
              });
            } else {
              maxDocUploadDate = data[0].documentUploadDate
                ? moment(data[0].documentUploadDate.toString()).format(
                    'YYYY-MM-DD,h:mm:ss'
                  )
                : '';
              this.pdfSrc = data[0].urlMediaLink;
            }
            this.toBeDownloaded = false;
            setTimeout(() => {
              $('#printModel').modal('show');
            });
          } else {
            this.alertService.openErrorDialogBox('No Document Found');
          }
        },
        (error) => {
          this.loaderService.stop();
        }
      );
  }
  downloadPDF(consolidatedId: any) {
    this.loaderService.start();

    let data: any;
    let code = '';
    let message = '';
    let docUploadDate = '';
    let maxDocUploadDate = '';
    let fileURL = '';
    let requestJson = {
      transactionId: consolidatedId,
    };

    this.serviceDocService
      .post('wiseservice/document/downloadDocument', requestJson)
      .subscribe(
        (response) => {
          this.loaderService.stop();

          data = response.data;
          code = response.code;
          message = response.message;
          if (code == '200' && data != undefined && data.length > 0) {
            if (data.length > 1) {
              maxDocUploadDate = data[0].documentUploadDate
                ? moment(data[0].documentUploadDate.toString()).format(
                    'YYYY-MM-DD,h:mm:ss'
                  )
                : '';

              data.forEach((element: any) => {
                docUploadDate = element.documentUploadDate
                  ? moment(element.documentUploadDate.toString()).format(
                      'YYYY-MM-DD,h:mm:ss'
                    )
                  : '';
                if (docUploadDate > maxDocUploadDate) {
                  maxDocUploadDate = docUploadDate;
                  this.downloadUrl = element.urlMediaLink;
                }
              });
            } else {
              maxDocUploadDate = data[0].documentUploadDate
                ? moment(data[0].documentUploadDate.toString()).format(
                    'YYYY-MM-DD,h:mm:ss'
                  )
                : '';
              this.downloadUrl = data[0].urlMediaLink;
            }
            // this.toBeDownloaded = true;
            FileSaver.saveAs(this.downloadUrl, consolidatedId);
          } else {
            this.alertService.openErrorDialogBox('No Document Found');
          }
        },
        (error) => {
          this.loaderService.stop();
        }
      );
  }
  print() {
    printJS({ printable: this.pdfSrc, type: 'pdf', showModal: true });
  }
  selectDoc(element: any) {}
  dmList: any = [];
  selectedDMs: any = [];
  serviceProvidersList: any = [];
  techniciansList: any = [];
  selectedSp: any = [];
  selectedTechnicians: any = [];
  getRoleHierarchyMapping() {
    this.httpService
      .get('roleHierarchyMapping/v1/getRoleHierarchyMapping')
      .subscribe(
        (data: any) => {
          this.dmList = data.districtManagerDetails;
          this.selectedDMs = [];
        },
        (error) => {
          this.loaderService.stop();
        }
      );
  }
  onDMDropDownClose() {
    this.serviceProvidersList = [];
    this.selectedDMs == null
      ? (this.selectedDMs = this.dmList)
      : (this.selectedDMs = this.selectedDMs);
    this.dmList.forEach((dm: any) => {
      this.selectedDMs.forEach((selectedDm: any) => {
        if (dm.dmId == selectedDm.dmId) {
          this.serviceProvidersList = this.serviceProvidersList.concat(
            dm.serviceProviderDetailsList
          );
        }
      });
    });
  }
  onDmDeSelect() {
    this.selectedSp = [];
    this.serviceProvidersList = [];
    this.techniciansList = [];
  }
  onSPDropDownClose() {
    this.techniciansList = [];
    this.serviceProvidersList.forEach((sp: any) => {
      this.selectedSp.forEach((selectedSp: any) => {
        if (selectedSp.spId == sp.spId) {
          this.techniciansList = this.techniciansList.concat(
            sp.technicianDetailsList
          );
        }
      });
    });
  }
  addSocId(event: MatChipInputEvent): void {
    this.addChip(event, this.socIds);
  }
  removeSocId(keyword: any) {
    this.removeChip(keyword, this.socIds);
  }
  addSoId(event: MatChipInputEvent): void {
    this.addChip(event, this.soIds);
  }
  removeSoId(keyword: any) {
    this.removeChip(keyword, this.soIds);
  }
  addconsolidatedNumber(event: MatChipInputEvent): void {
    this.addChip(event, this.consolidatedNumbers);
  }
  removeconsolidatedNumber(keyword: any) {
    this.removeChip(keyword, this.consolidatedNumbers);
  }
  addChip(event: MatChipInputEvent, data: any) {
    let input = event.input;
    let value = event.value;
    // Add our keyword
    if ((value || '').trim()) {
      data.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  removeChip(keyword: any, data: any): void {
    let index = data.indexOf(keyword);
    if (index >= 0) {
      data.splice(index, 1);
    }
  }
  pasteChips(event: ClipboardEvent | any, fieldName: string): void {
    event.preventDefault(); //Prevents the default action
    event.clipboardData
      .getData('Text') //Gets the text pasted
      .split(/;|,|\n| /) //Splits it when a SEMICOLON or COMMA or NEWLINE
      .forEach((value: any) => {
        if (value.trim()) {
          if (fieldName == 'socId') {
            this.socIds.push(value.trim()); //Push if valid
          } else if (fieldName == 'soId') {
            this.soIds.push(value.trim());
          } else if (fieldName == 'consolidatedNo') {
            this.consolidatedNumbers.push(value.trim());
          }
        }
      });
  }
  retrieveConsolidatedClaimsList() {
    this.loaderService.start();
    let formData = this.advancedSearchForm.getRawValue();
    this.dataShareService.setConsRetrieveData(formData);
    let data = {
      languageKey: 'EN',
      userID: this.dataShareService.getLocalStorage('userId'),
      userProfile: this.userRole,
      vendorCode:
        this.userRole != 'SP'
          ? this.retriveValueFromMultiselect(formData.servicePartners, 'spId')
          : [this.dataShareService.getLocalStorage('customerNumber')],
      consolidatedClaimID: this.consolidatedNumbers,

      consolidatedClaimDateFrom: formData.cons_start_date
        ? moment(formData.cons_start_date.toString()).format('YYYY-MM-DD')
        : '',
      consolidatedClaimDateTo: formData.cons_end_date
        ? moment(formData.cons_end_date.toString()).format('YYYY-MM-DD')
        : '',
      consolidatedClaimStatus:
        this.retriveValueFromMultiselect(formData.documentStatus, 'estat')
          .length > 0
          ? this.retriveValueFromMultiselect(formData.documentStatus, 'estat')
          : [],
      socID: this.socIds,
      soID: this.soIds,
    };
    this.httpService
      .post('claimApi/v1/retrieveConsolidatedClaimsList', data)
      .subscribe(
        (data) => {
          this.filterConsolidateClaim();
        },
        (error) => {
          this.loaderService.stop();
        }
      );
  }
  filterConsolidateClaim() {
    this.loaderService.start();
    this.isTableDataSet = false;
    let formData = this.advancedSearchForm.getRawValue();
    this.dataShareService.setFilterConsolidatedRequestParameter(
      this.advancedSearchForm.getRawValue()
    );
    this.dataShareService.setConsFilterData(formData);
    console.log('filterConsolidateClaim()', formData);
    let dto = {
      consolidateIds: this.consolidatedNumbers,
      socIds: [],
      soIds: [],
      vendorId:
        this.retriveValueFromMultiselect(formData.servicePartners, 'spId')
          .length > 0
          ? this.retriveValueFromMultiselect(formData.servicePartners, 'spId')
          : [],
      consolidateDateFrom:
        this.socIds.length > 0 ||
        this.soIds.length > 0 ||
        this.consolidatedNumbers.length > 0
          ? ''
          : formData.cons_start_date
          ? moment(formData.cons_start_date.toString()).format('YYYY-MM-DD')
          : '',
      consolidateDateTo:
        this.socIds.length > 0 ||
        this.soIds.length > 0 ||
        this.consolidatedNumbers.length > 0
          ? ''
          : formData.cons_end_date
          ? moment(formData.cons_end_date.toString()).format('YYYY-MM-DD')
          : '',
    };
    let dtoForSocDetails = {
      accountingIndicator: [],
      appointmentDateFrom: '',
      appointmentDateTo: '',
      callDateFrom: '',
      callDateTo: '',
      creationDateFrom: '',
      creationDateTo: '',
      customerNumber: [],
      documentStatus: [],
      documentType: [],
      externalReference: [],
      serviceType: [],
      technician: [],
      documentNumber: [],
      spVendorCode:
        this.userRole == 'SP' ? [this.customerNumber] : dto.vendorId,
      userId: this.userId,
      token: this.userToken,
      isConsolidated: true,
    };
    this.dataShareService.setLocalStorage(
      'consolidateSocFilterParameter',
      JSON.stringify(dtoForSocDetails)
    );
    this.httpService.post('claimApi/v1/filterConsolidateClaim', dto).subscribe(
      (data) => {
        this.dataShareService.setConsolidatedList(
          data.response.consolidateClaimInfo
        );
        this.dataSource = new MatTableDataSource(
          data.response.consolidateClaimInfo
        );
        this.dataShareService.setLocalStorage(
          'lastFetchTime',
          data.response.lastDataFetchTime != null
            ? data.response.lastDataFetchTime
            : ''
        );
        this.dataShareService.setConsolidatedMainFilterFlag(false);
        setTimeout(() => {
          this.dataSource.sort = this.sort;
        }, 3000);
        this.setPaginator();
        this.mainFilter = false;
        this.loaderService.stop();
        this.isTableDataSet = true;
      },
      (err) => {
        this.loaderService.stop();
        this.isTableDataSet = false;
        console.log(err);
      }
    );
  }
  retriveValueFromMultiselect(data: any, keyName: any) {
    let values: any = [];
    if (data && data.length > 0) {
      data.forEach((element: any) => {
        values.push(element[keyName]);
      });
    }
    return values;
  }
  filterSOcs(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  onStartDateChange(startDate: any, rangeInput: any) {
    var date: any = '';
    let minDate: any;
    let maxDate: any = '';
    let selectedStartDate: any = '';
    this.advancedSearchForm
      .get([rangeInput._endInput.ngControl.name])
      .clearValidators();
    this.advancedSearchForm
      .get([rangeInput._endInput.ngControl.name])
      .updateValueAndValidity();
    if (startDate.value) {
      // minDate = startDate.value;
      selectedStartDate = new Date(startDate.value);
      selectedStartDate.setDate(selectedStartDate.getDate() - 89);
      minDate = selectedStartDate;
      date = new Date(startDate.value);
      date = new Date(startDate.value);
      date.setDate(date.getDate() + 89);
      if (date > new Date()) {
        maxDate = new Date();
      } else {
        maxDate = date;
      }
    }
    rangeInput.max = maxDate;
    rangeInput.min = minDate;
  }
  clearDate(event: any, rangeInput: any) {
    this.advancedSearchForm
      .get([rangeInput._endInput.ngControl.name])
      .setValue('');
    this.advancedSearchForm
      .get([rangeInput._startInput.ngControl.name])
      .setValue('');
    this.advancedSearchForm
      .get([rangeInput._endInput.ngControl.name])
      .clearValidators();
    this.advancedSearchForm
      .get([rangeInput._endInput.ngControl.name])
      .updateValueAndValidity();
    this.advancedSearchForm
      .get([rangeInput._startInput.ngControl.name])
      .clearValidators();
    this.advancedSearchForm
      .get([rangeInput._startInput.ngControl.name])
      .updateValueAndValidity();
    rangeInput.max = new Date();
    rangeInput.min = '';
  }
}
