import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SideNavComponent } from '../app/side-nav/side-nav.component';
import { ClaimManagementComponent } from './claim-management/claim-management.component';
import { ConsolidatedClaimComponent } from './consolidated-claim/consolidated-claim.component';
import { NewClaimComponent } from './new-claim/new-claim.component';
import { LoginComponent } from './login/login.component';
import { SocDetailsComponent } from './claim-management/soc-details/soc-details.component';
import { LogsComponent } from './logs/logs.component';
import { 
  AuthGuardService as AuthGuard 
} from './services/auth-guard.service';
import { DocumentDetailsComponent } from './consolidated-claim/document-details/document-details.component';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },

  {
    path: 'home',
    component: SideNavComponent, // this is the component with the <router-outlet> in the template
    children: [
     
      { path: 'claim-management', component: ClaimManagementComponent,canActivate: [AuthGuard]  },
      { path: 'consolidated-claim', component: ConsolidatedClaimComponent, canActivate: [AuthGuard] },
      { path: 'new-claim', component: NewClaimComponent,canActivate: [AuthGuard] },
      { path: 'soc-details/:id', component: SocDetailsComponent,canActivate: [AuthGuard] },
      { path: 'doc-details/:id', component: DocumentDetailsComponent,canActivate: [AuthGuard] },
      { path: 'soap-logs', component: LogsComponent,canActivate: [AuthGuard] },

    ],
  },

  

  // { path: 'sidenav', component: SideNavComponent, canActivate: [AuthGuard] },
  // { path: 'claim-management', component: ClaimManagementComponent,canActivate: [AuthGuard]  },
  // { path: 'consolidated-claim', component: ConsolidatedClaimComponent, canActivate: [AuthGuard] },
  // { path: 'new-claim', component: NewClaimComponent },
  // { path: 'login', component: LoginComponent },
  // { path: 'soc-details', component: SocDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
