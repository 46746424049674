import { Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {StaticData} from 'src/app/shared/staticData';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {MatSort} from '@angular/material/sort';
import {MatOption} from '@angular/material/core';
import {CommonService} from '../../services/common.service';
import {AppService} from '../../services/app.service';
import {DocumentDetailsService} from './document-details.service';
import {HttpService} from '../../services/http.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {DataShareService} from'../../services/data-share.service'
import 'hammerjs';
import 'hammer-timejs';
import { MatChipInputEvent } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss']
})
export class DocumentDetailsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('select')
  select!: MatSelect;
  allSelected = false;
  pageType = 'document_details';
  @ViewChild(MatSort) sort: MatSort | any;

  displayedColumns :any=[];
  dataSource :any;
  columnsToDisplay: any = [];
  advancedSearchForm: any;
  dropdownSettings: any;
  statuses:any=[] 
  columnFilterControl = new FormControl([]);
  columnsOption: any; 
  userId: any;
  consolidatedId:any;
  columnMappingsData: any = {};
  masterData:any=[]
  currentSocIndex:any;
  currentData:any=[];
  consolidatedClaimList:any=[];
  documentNumbers:any=[];
  externalReferences:any=[];
  accountingIndicators:any=[];
  technicians:any=[];
  serviceTypes:any=[];
  status:any=[];
  totalAmount:any=[];
  creditMemo:any=[];
  userRole:any;
  innerWidth:any=0;
  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private commonService: CommonService,
              private appService: AppService,
              private documentDetailsService: DocumentDetailsService,
              private loaderService: NgxUiLoaderService,
              private httpService: HttpService,
              private activatedRoute:ActivatedRoute,
              private dataShareService:DataShareService
  ) {
    this.appService.setTitle('Consolidated Claim');
    this.consolidatedId = this.activatedRoute.snapshot.paramMap.get('id');
    this.consolidatedClaimList = this.dataShareService.getConsolidatedList();
    
    this.getCurrentData();
    this.advancedSearchForm = this.formBuilder.group({
      doc_no: [''],
      doc_external_ref: [''],
      doc_technicians: [''],
      doc_ac_ind: [''],
      doc_service_type: [''],
      doc_status: [''],
      doc_creadtitmemo: [''],
      doc_total_amount: [''],
    });
    this.userId = this.dataShareService.getLocalStorage('userId');
    this.userRole = this.dataShareService.getLocalStorage('role');

  }
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.dropdownSettings = {
      singleSelection: false,
      lazyLoading:true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.getUserColumnMaping();
    
 }

  getUserColumnMaping() {
    this.loaderService.start();
    this.httpService.get('columnPreferenceApi/v1/getColumnMappings?pageType=' + this.pageType).subscribe((data: any) => {
      this.columnMappingsData = data.columnMappings;
        for (var element in data.columnMappings) {
          if (data.columnMappings.hasOwnProperty(element)) {
            let obj:any={}
            obj['id'] = element;
            obj['value'] = data.columnMappings[element];
            if(element=="socId"){
              this.displayedColumns.splice(0, 0, obj);
            }else{
              this.displayedColumns.push(obj);
            }
          } 
        }
        this.columnsOption = this.displayedColumns;
        this.getUserColumnPref();
      },
      error => {
        this.loaderService.stop();
      });
  }

  // Fetch all user column preference
  getUserColumnPref() {
    this.loaderService.start();
    let amountFields:any=[]
    this.httpService.get('columnPreferenceApi/v1/getUserColumnPreferences?userId=' + this.userId).subscribe((data: any) => {
      let hasPageTypeData:boolean=false; 
      if (data.userPreferenceActive) {
          data.columnPreferencesList.forEach((list:any) => {
            if(list.pageType == this.pageType){
              hasPageTypeData=true;
              if(list.preferredColumnList.length==0 || list.preferredColumnList[0]==""){
                this.displayedColumns.forEach((element:any)=>{
                    this.columnsToDisplay.push(element.id); 
                    if(element.id == 'docNumber'){
                      this.columnsToDisplay.unshift(element.id);  
                    }else{
                      this.columnsToDisplay.push(element.id);
                    }               
                  })
              }else{
                  list.preferredColumnList.forEach((element:any)=>{
                    if(element == 'docNumber'){
                      this.columnsToDisplay.unshift(element);  
                    }else if(element.toLowerCase().includes('amount')){
                      amountFields.push(element);
                    }else {
                      this.columnsToDisplay.push(element);
                    }
                  })
              }
          }
         
          });
          if(!hasPageTypeData){
            this.displayedColumns.forEach((element:any) => {
              if(element.id == 'docNumber'){
                this.columnsToDisplay.unshift(element.id);  
              }else{
                this.columnsToDisplay.push(element.id);
              }
            });
          }
          amountFields.forEach((element:any) => {
            this.columnsToDisplay.push(element);
          });

        } else {
          this.displayedColumns.forEach((element:any) => {
            if(element.id == 'docNumber'){
              this.columnsToDisplay.unshift(element.id);  
            }else{
              this.columnsToDisplay.push(element.id);
            }
          });
        }
        this.loaderService.stop();
        this._tempColumnsToDisplay = JSON.parse(JSON.stringify(this.columnsToDisplay));
      },
      error => {
        this.loaderService.stop();
      });
  }


  ngAfterViewInit() {
  }

  goBack() {
    this.router.navigate(['home/consolidated-claim'], {state: {data: 'fromDocDetail'}});
  }

  resetAdvanceFilterForm() {
    this.advancedSearchForm.reset();
  }

  // *****Column filter start******
  _tempColumnsToDisplay:any=[];
  addColumn() {
    this.columnFilterControl.value.forEach((element: any) => {
      if (!this._tempColumnsToDisplay.includes(element)) {

        if (element === 'docNumber') {
          this._tempColumnsToDisplay.unshift(element);
        }else {
          this._tempColumnsToDisplay.push(element);
        }
        if (!this._tempColumnsToDisplay.includes('docNumber')) {
          this._tempColumnsToDisplay.unshift('docNumber');
        }
      }
    });
  }

  removeColumn() {
    if (this._tempColumnsToDisplay.length) {
      const columnsToDisplay = JSON.parse(JSON.stringify(this._tempColumnsToDisplay));
      this._tempColumnsToDisplay.forEach((element: any) => {
        if (!this.columnFilterControl.value.includes(element)) {
          const i = columnsToDisplay.indexOf(element);
          if (i !== -1) {
            columnsToDisplay.splice(i, 1);
          }
        }
        if (this.columnFilterControl.value.length === 0) {
          this._tempColumnsToDisplay = [];
        }
      });
      this._tempColumnsToDisplay = columnsToDisplay;
      if (!this._tempColumnsToDisplay.includes('docNumber')) {
        this._tempColumnsToDisplay.unshift('docNumber');
      }
    }
  }

  initialSelect() {
    this.select.options.forEach((item: MatOption) => {
      if (this._tempColumnsToDisplay.includes(item.value)) {
        item.select();
      }
    });
    if (this._tempColumnsToDisplay.length == this.displayedColumns.length) {
      this.allSelected = true;
    }
  }

  onAdvancedFilterModelClosed(): any {
    let amountFields:any=[]
    var length = this._tempColumnsToDisplay.length
    while (length--) {
        if (this._tempColumnsToDisplay[length].toLowerCase().includes('amount')) { 
          amountFields.push(this._tempColumnsToDisplay[length])
          this._tempColumnsToDisplay.splice(length,1);
        } 
    }
    this._tempColumnsToDisplay = this._tempColumnsToDisplay.concat(amountFields);
    let queryParams = '';
    this.columnsToDisplay =JSON.parse(JSON.stringify(this._tempColumnsToDisplay));
    this.columnsToDisplay.forEach((element: any) => {
      queryParams += 'columnList=' + element + '&';
    });
    queryParams += 'pageType=document_details&';
    queryParams += 'userId=' + this.userId;
    this.documentDetailsService.setColumnsPreferences(queryParams);
  }


  toggleAllSelection(): any {
    this._tempColumnsToDisplay = [];
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
      this.addColumn();
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
      this._tempColumnsToDisplay = ['docNumber'];
    }
  }

  optionClick(): any {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    this.addColumn();
    this.removeColumn();
  }

  onColumnRemoved(col: string) {
    const cols = this.columnFilterControl.value as string[];
    this.removeFirst(cols, col);
    this.columnFilterControl.setValue(cols); // To trigger change detection
    this.allSelected = false;
    this.removeColumn();
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

// *****Column filter End******
exportToExcel() {
  let consClaimSocTableColumn = [
    {label:'Document Number',value:'docNumber'},
    {label:'Document Description',value:'docDescription',},
    {label:'External Reference',value:'externalReference',},
    {label:'Technician',value:'technician'},
    {label:'Accounting Indicator',value:'accountingIndicator'},
    {label:'Service Type',value:'serviceType'},
    {label:"Document Type",value:'docType'},
    {label:'Status',value:'status'},
    {label:'Total Amount',value:'totalAmount'},
    {label:'Service Amount',value:'serviceAmount'},
    {label:'Material Amount',value:'materialAmount'},
    {label:'Credit Memo',value:'creditMemo'}
   ];
  let paged = this.commonService.prepareTableDataForCsv(this.dataSource,this.columnsToDisplay,this.columnsOption,this.paginator,this.currentData?.currency);
  let fields = this.commonService.headerNameChange(paged,consClaimSocTableColumn);
  this.commonService.downloadCSV(paged,StaticData.consolidatedClaimDetailsSheet,fields)
}
nextSoc(){ 
  let nextConsolidatedId=this.consolidatedId;
  for(let i=0;i<this.consolidatedClaimList.length;i++){
    if(this.consolidatedId == this.consolidatedClaimList[i].consolidatedId){
      this.currentSocIndex = i;
      if(this.consolidatedClaimList[i+1]){
        nextConsolidatedId = this.consolidatedClaimList[i+1].consolidatedId;
      }
      break;
    }
  }
  if(this.currentSocIndex<this.consolidatedClaimList.length-1){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['home/doc-details', nextConsolidatedId]);
  }

}
previousSoc(){
  let nextConsolidatedId=this.consolidatedId;
  for(let i=0;i<this.consolidatedClaimList.length;i++){
    if(this.consolidatedId == this.consolidatedClaimList[i].consolidatedId){
      this.currentSocIndex = i;
      if(this.consolidatedClaimList[i-1]){
        nextConsolidatedId = this.consolidatedClaimList[i-1].consolidatedId;
      }
      break;
    }
  }
  if(this.currentSocIndex>0){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['home/doc-details', nextConsolidatedId]);
  }
}
onSwipeLeft(event:any){
  if(this.innerWidth<1024 && !event.target.classList.contains("custom-table")){
    this.nextSoc();
  }
}
onSwipeRight(event:any){
  if(this.innerWidth<1024 && !event.target.classList.contains("custom-table")){
    this.previousSoc();
  }
}

filterSOcs(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

getFilterDropdowns(){
  this.currentData.consolidateClaimItem.forEach((element:any) => {
    if(element.docNumber && !this.documentNumbers.includes(element.docNumber)){
      this.documentNumbers.push(element.docNumber)
    }
    if(element.externalReference && !this.externalReferences.includes(element.externalReference)){
      this.externalReferences.push(element.externalReference)
    }
    if(element.creditMemo && !this.creditMemo.includes(element.creditMemo)){
      this.creditMemo.push(element.creditMemo)
    }
    if(element.serviceType && !this.serviceTypes.includes(element.serviceType)){
      this.serviceTypes.push(element.serviceType)
    }
    if(element.status && !this.status.includes(element.status)){
      this.status.push(element.status)
    }
    if(element.totalAmount && !this.totalAmount.includes(element.totalAmount)){
      this.totalAmount.push(element.totalAmount)
    }
    if(element.accountingIndicator && !this.accountingIndicators.includes(element.accountingIndicator)){
      this.accountingIndicators.push(element.accountingIndicator)
    }
    if(element.technician && !this.technicians.includes(element.technician)){
      this.technicians.push(element.technician)
    }
    
  });
}

filterConsolidateClaim(){
  let formData=this.advancedSearchForm.getRawValue();
    let dto:any={
      "docNumber":formData.doc_no,
      "externalReference":formData.doc_external_ref,
      "accountingIndicator":formData.doc_ac_ind,
      "serviceType":formData.doc_service_type,
      "status":formData.doc_status,
      "creditMemo":formData.doc_creadtitmemo,
      "totalAmount":formData.doc_total_amount,
      "technician":formData.doc_technicians
  }


  let filterData:any=[];
    this.currentData.consolidateClaimItem.forEach((element:any) => {
      for (const property in element) {
        if(dto[property] && dto[property].length>0){
          if(dto[property].includes(element[property])){
            filterData.push(element);
            break;
          }
        }
      }
    });
    if(filterData.length>0){
      this.dataSource = new MatTableDataSource(filterData);
      setTimeout(()=>{
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      })
    }else{
      this.dataSource = new MatTableDataSource(this.currentData.consolidateClaimItem);
      setTimeout(()=>{
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      })
    }
}

getCurrentData(){
  let data=this.dataShareService.getConsolidatedList();
  if(!data){
    this.router.navigate(["home/consolidated-claim"]);
    this.loaderService.stop();

  }else{
    for(let i=0;i<data.length;i++){
      if(data[i].consolidatedId==this.consolidatedId){
        this.currentData =data[i];
        this.currentSocIndex = i;
        this.dataSource = new MatTableDataSource(this.currentData.consolidateClaimItem);
        setTimeout(()=>{
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        })
        break;
      }
    }
    this.loaderService.stop();

  }
  console.log(this.currentData)
  this.getFilterDropdowns();
}





}
