import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return value.split(/ (.+)/)[1];

  }

}
