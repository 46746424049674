import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppService } from '../services/app.service';
import { Router } from '@angular/router';
import { DataShareService } from '../services/data-share.service';
import { HttpService } from '../services/http.service';
import { timingSafeEqual } from 'crypto';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../services/common.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() SideNavToggle = new EventEmitter();  
  title: String | undefined;
  userName:any;
  lastFetchTime:any;
  currentRout:any;
  constructor(private appService: AppService, private router: Router,
     private dataShareService:DataShareService,private httpService:HttpService,private loaderService: NgxUiLoaderService,private commonService:CommonService) {
    this.appService.getTitle().subscribe((appTitle: String) => this.title = appTitle);
    this.userName = localStorage.getItem('userName');
   }
  
openSidenav() {
  this.SideNavToggle.emit();
}

ngOnInit(): void {
  this.getFetchTime();
}
logOut(){
  this.dataShareService.clearData()
  this.commonService.resetPreviousUrl();
  this.router.navigate(['/login']);
}
getFetchTime(){
  this.dataShareService.watchStorage().subscribe((data)=>{
    this.lastFetchTime= this.dataShareService.getLocalStorage('lastFetchTime');
  })
}
refresh(){
  this.currentRout = this.router.url

  if(this.currentRout.includes('claim-management') || this.currentRout.includes('soc-details')){
    this.retriveSocList();
  }else{
    this.retrieveConsolidatedClaimsList();
  }
}
retriveSocList(){
  this.loaderService.start();

  this.currentRout = this.router.url

  let data={
      "accountingIndicator": [],
      "appointmentDateFrom": "",
      "callDateFrom": "",
      "creationDateFrom": "",
      "appointmentDateTo": "",
      "callDateTo": "",
      "creationDateTo": "",
      "customer": [],
      "documentStatus": [],
      "language": "en",
      "searchType": "01",
      "serviceType": [],
      "soId": [],
      "socid": [],
      "spCustomerCode": [localStorage.getItem('customerNumber')],/**Also known as customerId */
      "technician": [],
      "trayId": "",
      "userId": localStorage.getItem('userId'),
      "userProfile": "",
      "toBeUpdated": false,
      "socDbId":""
     }
     this.dataShareService.setLocalStorage('retriveSocListRequestDto',JSON.stringify(data));
    this.httpService.post('claimApi/v1/retrieveSocList',data).subscribe((data)=>{
      if(this.currentRout.includes('claim-management')){
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['home/claim-management']);
        }else{
          this.router.navigate(['home/claim-management'])
        }
        this.loaderService.stop();
    },error=>{
      this.loaderService.stop();
   })
}
retrieveConsolidatedClaimsList(){
  this.currentRout = this.router.url
  this.loaderService.start();

  let data={
      "languageKey": "EN",
      "userID": localStorage.getItem('userId'),
      "userProfile": "",
      "vendorCode": localStorage.getItem('customerNumber'),
      "consolidatedClaimID": "",
      "consolidatedClaimDateFrom": "",
      "consolidatedClaimDateTo": "",
      "consolidatedClaimStatus": ""
    }
     
    this.httpService.post('claimApi/v1/retrieveConsolidatedClaimsList',data).subscribe((data)=>{
        if(this.currentRout.includes('consolidated-claim')){
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['home/consolidated-claim']);
        }else{
          this.router.navigate(['home/consolidated-claim'])
        }
        this.loaderService.stop();
    },error=>{
      this.loaderService.stop();
   })
}
}
