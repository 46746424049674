<div class="container-fluid mt-3" >
    <!-- *ngIf="userRole=='DM' || userRole=='AM'" -->
    <mat-card *ngIf='mainFilter' class="mainFilterCard">
        <div class="mainFilterHeading" >
            <h3>Main Filter</h3>
            <button type="button" class="btn btn-dark " (click)="cancelSearchAgain()">
                <mat-icon class="d-block"> close</mat-icon>
            </button>
         </div>
        <form [formGroup]="advancedSearchForm" class="row g-2 firstFilter">
            <div class="col-12 col-md-6 col-sm-6 col-lg-4" *ngIf="userRole=='SP'">
                <mat-form-field appearance="outline" class="custom-mat-form-field">
                    <mat-label>Vendor</mat-label>
                    <input matInput placeholder="Enter vendor" [value]="customerNumber" type="text" disabled>
                </mat-form-field>
            </div>
          <div class="col-12 col-md-6 col-sm-6 col-lg-4" *ngIf="userRole=='DM' || userRole=='AM'">
            <ng-multiselect-dropdown formControlName="districtsManagers"
                [placeholder]="'District Managers'"
                [settings]="dmDropdownSettings"
                [(ngModel)]="selectedDMs"
                (onDropDownClose)="onDMDropDownClose()"
                [data]="dmList"
                (onDeSelect)="onDmDeSelect()"
                (onDeSelectAll)="onDmDeSelect()"
                >
            </ng-multiselect-dropdown>
        </div>
        <div class="col-12 col-md-6 col-sm-6  col-lg-4" *ngIf="userRole=='DM' || userRole=='AM'">
            <ng-multiselect-dropdown formControlName="servicePartners"
                [placeholder]="'Service Providers*'"
                [settings]="spDropdownSettings"
                [data]="serviceProvidersList"
                [(ngModel)]="selectedSp" 
                (onDropDownClose)="onSPDropDownClose()"
                >
            </ng-multiselect-dropdown>
        </div>
     
  
 
            <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                <mat-form-field appearance="outline" class="custom-mat-form-field">
                    <mat-chip-list #consolidatedNumbersChipList formControlName='cons_number'>
                        <mat-chip *ngFor="let keyword of consolidatedNumbers" [selectable]="selectable" [removable]="removable" (removed)="removeconsolidatedNumber(keyword)">
                            {{keyword}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input placeholder="Enter Consolidated IDs..." [matChipInputFor]="consolidatedNumbersChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                        [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addconsolidatedNumber($event)" (paste)="pasteChips($event,'consolidatedNo')"/>
                    </mat-chip-list>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-sm-6  col-lg-4 custom-picker-right">
                <mat-form-field appearance="outline" class="custom-mat-form-field">
                    <mat-label>Consolidated Date </mat-label>
                    <mat-date-range-input #bookingdateRangeInput [rangePicker]="bookingdate">
                        <input matStartDate formControlName='cons_start_date' placeholder="Start date" (dateChange)="onStartDateChange($event,bookingdateRangeInput)">
                        <input matEndDate formControlName='cons_end_date' placeholder="End date">
                    </mat-date-range-input>
                    <mat-icon matDatepickerToggleIcon class="date-clear" *ngIf="advancedSearchForm.get('cons_start_date').value" (click)="clearDate($event,bookingdateRangeInput)">clear</mat-icon>
                    <mat-datepicker-toggle matSuffix [for]="bookingdate"></mat-datepicker-toggle>
                    <mat-date-range-picker #bookingdate twoWayRangeSelect="90"></mat-date-range-picker>
                    <!-- maxRange=30 -->
                </mat-form-field>
                <!-- <mat-form-field appearance="outline" class="custom-mat-form-field">
                    <mat-label>Consolidated Date </mat-label>
                    <input  matInput autocomplete="off"
                        #consolidatedDatePicker
                        ngxDaterangepickerMd
                        [formControl]="advancedSearchForm.controls['consolidatedDate']"
                        [showCustomRangeLabel]="true"
                        [alwaysShowCalendars]="true"
                        [ranges]="ranges"
                        [linkedCalendars]="true"
                        [showClearButton]="true"
                        [dateLimit]="29"
                        [maxDate]="maxDate"
                        placeholder="Consolidated Date" readonly/>
                </mat-form-field> -->
                <div class="rangeMsg">Max Date Range 90 Days</div>
            </div>


            <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                <mat-form-field appearance="outline" class="custom-mat-form-field">
                    <mat-chip-list #soIdchipList formControlName='so_id'>
                        <mat-chip *ngFor="let keyword of soIds" [selectable]="selectable" [removable]="removable" (removed)="removeSoId(keyword)">
                            {{keyword}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input placeholder="Enter Service Order IDs..." [matChipInputFor]="soIdchipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                        [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addSoId($event)" (paste)="pasteChips($event,'soId')"/>
                    </mat-chip-list>
                </mat-form-field>
            </div>

            <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                <mat-form-field appearance="outline" class="custom-mat-form-field">
                    <mat-chip-list #socIdchipList formControlName='socId'>
                        <mat-chip *ngFor="let keyword of socIds" [selectable]="selectable" [removable]="removable" (removed)="removeSocId(keyword)">
                            {{keyword}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input placeholder="Enter Service Confirmation IDs..." [matChipInputFor]="socIdchipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                        [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addSocId($event)" (paste)="pasteChips($event,'socId')"/>
                    </mat-chip-list>
                </mat-form-field>
            </div>
            <div class="text-center mt-5 w-100">
                <button type="button" class="btn-light custom-theme-btn btn mr-2" (click)="resetMainFilterForm()">Reset</button>
                <button type="submit" class="custom-theme-btn btn btn-primary" [disabled]="userRole !='SP' && !advancedSearchForm.valid" (click)="mainFilterSearch()">Search</button>
              </div>
        </form>
    </mat-card>
</div>


<div class="container-fluid " *ngIf='!mainFilter'>
    <div class="table-actions">
        <input class="form-control searchInput" (keyup)="filterSOcs($event)" placeholder="Filter Results..." #input>
        <div>
            <div class="btn-action mr-1" style="color: white;" >
                <!-- *ngIf="userRole=='DM' || userRole=='AM'" -->
                <button mat-stroked-button class="custom-theme-btn btn btn-primary searchAgainBtn" (click)="searchAgain()">
                  <mat-icon>search</mat-icon> Search Again
                </button>
            </div>

        </div>
        <div class="btn-action mr-1" style="color: white;" >
            <button mat-stroked-button class="btn btn-primary border-0 font-weight-normal" (click)="exportToExcel()"> 
                <mat-icon>file_download</mat-icon> Download
            </button>
        </div>
        <button mat-mini-fab matTooltip="Filters" matTooltipClass="tooltipClass" color="primary" 
        data-toggle="modal" data-target="#exampleModalCenter"  (click)="initialSelect()">
            <mat-icon>filter_alt</mat-icon>
        </button>

        <!-- <div class="date-toggle">
            <button mat-mini-fab matTooltip="Filters" matTooltipClass="tooltipClass" color="primary" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>filter_list</mat-icon>
            </button>
            </div>

            <mat-menu #menu="matMenu">
                <button data-toggle="modal" data-target="#exampleModalCenter" mat-menu-item (click)="initialSelect()">
            <mat-icon>filter_alt</mat-icon> {{userRole=='SP'?'Search':'Advance Filter'}}
            </button>
                    <button mat-menu-item (click)="exportToExcel()">
                <mat-icon>description</mat-icon> Download Sheet
            </button>
            </mat-menu> -->

    </div>

    <section class="example-container mat-elevation-z8">
        <div class="custom-table">
            <mat-table #table [dataSource]="dataSource" matSort class="table mb-0">
                <ng-container [matColumnDef]="column.id" *ngFor="let column of displayedColumns">
                    <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column.id=='action' ? true : false "> {{column.value}} </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="w-100">
                        <span class="mobile-label w-100">{{column.value}}:</span>


                        <span class="w-100 pl-3" *ngIf="column.id =='consolidatedId'"><a [routerLink]="['/home/doc-details',element.consolidatedId]" (click)="selectDoc(element)">{{element[column.id]}}</a></span>
                        <span class="w-100 pl-3" *ngIf="column.id =='totalAmount'">{{element[column.id]?.toString().endsWith('-')?element[column.id]:element[column.id] | number : '1.2'}} {{element['currency']}}</span>
                        <span class="w-100 pl-3" *ngIf="column.id =='action'" >
                            <mat-icon (click)="openPrintModel(element.consolidatedId)">print</mat-icon><span></span>
                            <mat-icon  style="padding-left: 15px;" (click)="downloadPDF(element.consolidatedId)">cloud_download</mat-icon>
                        </span>
                        <span class="w-100 pl-3" *ngIf="column.id.includes('Date')">{{element[column.id] | date: 'yyyy-MM-dd'}}</span>
                        <span class="w-100 pl-3" *ngIf="column.id !='action' && column.id !='consolidatedId' && !column.id.includes('Date') && column.id !='totalAmount'">{{element[column.id]}}</span>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
            </mat-table>
            <table class="w-100">
                <tr *ngIf="isTableDataSet && !dataSource.data.length">
                  <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
                    No Records Found!
                  </td>
                </tr>
              </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10,20,25]" [showFirstLastButtons]="true">></mat-paginator>
    </section>

    <!-- advanced filter Modal -->
    <div class="modal fade bd-example-modal-lg custom-modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title mb-0" id="exampleModalLongTitle">Detailed Search</h2>
                    <h2 class="modal-title mb-0 float-right" style="cursor: pointer" data-dismiss="modal">
                        <mat-icon>close</mat-icon>
                    </h2>
                </div>
                <div class="modal-body">
                    <mat-tab-group dynamicHeight>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon class="example-tab-icon mr-1">filter_alt</mat-icon>
                                <!-- {{userRole=='SP'?'Search':'Advance Filter'}} -->
                                Advance Filter
                            </ng-template>

                            <div class="container-fluid">

                                <form [formGroup]="advancedSearchForm" class="row g-2">
                   
                                    <div class="col-12 col-md-6 col-sm-6 col-lg-4" *ngIf="userRole=='SP'">
                                        <mat-form-field appearance="outline" class="custom-mat-form-field">
                                            <mat-label>Vendor</mat-label>
                                            <input matInput placeholder="Enter vendor" [value]="customerNumber" type="text" disabled>
                                        </mat-form-field>
                                    </div>
                               
                                    <div class="col-12 col-md-6 col-sm-6 col-lg-4" *ngIf="userRole=='DM' || userRole=='AM'">
                                        <ng-multiselect-dropdown formControlName="districtsManagers"
                                            [placeholder]="'District Managers'"
                                            [settings]="dmDropdownSettings"
                                            [(ngModel)]="selectedDMs"
                                            (onDropDownClose)="onDMDropDownClose()"
                                            [data]="dmList"
                                            >
                                        </ng-multiselect-dropdown>
                                    </div>
                                    <div class="col-12 col-md-6 col-sm-6  col-lg-4" *ngIf="userRole=='DM' || userRole=='AM'">
                                      <ng-multiselect-dropdown formControlName="servicePartners"
                                        [placeholder]="'Service Providers*'"
                                        [settings]="spDropdownSettings"
                                        [data]="serviceProvidersList"
                                        [(ngModel)]="selectedSp" 
                                        (onDropDownClose)="onSPDropDownClose()"
                                        >
                                    </ng-multiselect-dropdown>
                                  </div>
                                  <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                                        <mat-form-field appearance="outline" class="custom-mat-form-field">
                                            <mat-chip-list #consolidatedNumbersChipList formControlName='cons_number'>
                                                <mat-chip *ngFor="let keyword of consolidatedNumbers" [selectable]="selectable" [removable]="removable" (removed)="removeconsolidatedNumber(keyword)">
                                                    {{keyword}}
                                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                                </mat-chip>
                                                <input placeholder="Enter Consolidated IDs..." [matChipInputFor]="consolidatedNumbersChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                                                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addconsolidatedNumber($event)" (paste)="pasteChips($event,'consolidatedNo')"/>
                                            </mat-chip-list>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-md-6 col-sm-6  col-lg-4 custom-picker-right">
                                        <mat-form-field appearance="outline" class="custom-mat-form-field">
                                            <mat-label>Consolidated Date </mat-label>
                                            <mat-date-range-input #bookingdateRangeInput [rangePicker]="bookingdate" >
                                                <input matStartDate formControlName='cons_start_date' placeholder="Start date" (dateChange)="onStartDateChange($event,bookingdateRangeInput)">
                                                <input matEndDate formControlName='cons_end_date' placeholder="End date">
                                            </mat-date-range-input>
                                            <mat-icon matDatepickerToggleIcon class="date-clear" *ngIf="advancedSearchForm.get('cons_start_date').value" (click)="clearDate($event,bookingdateRangeInput)">clear</mat-icon>
                                            <mat-datepicker-toggle matSuffix [for]="bookingdate"></mat-datepicker-toggle>
                                            <mat-date-range-picker #bookingdate twoWayRangeSelect="90"></mat-date-range-picker>
                                        </mat-form-field>
                                        <!-- <mat-form-field appearance="outline" class="custom-mat-form-field">
                                            <mat-label>Consolidated Date </mat-label>
                                            <input  matInput autocomplete="off"
                                                #consolidatedDatePicker
                                                ngxDaterangepickerMd
                                                [formControl]="advancedSearchForm.controls['consolidatedDate']"
                                                [showCustomRangeLabel]="true"
                                                [alwaysShowCalendars]="true"
                                                [ranges]="ranges"
                                                [linkedCalendars]="true"
                                                [showClearButton]="true"
                                                [dateLimit]="29"
                                                [maxDate]="maxDate"
                                                placeholder="Consolidated Date" readonly/>
                                        </mat-form-field> -->
                                        <div class="rangeMsg">Max Date Range 90 Days</div>
                                    </div>

                                   
                                    <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                                        <mat-form-field appearance="outline" class="custom-mat-form-field">
                                            <mat-chip-list #soIdchipList formControlName='so_id'>
                                                <mat-chip *ngFor="let keyword of soIds" [selectable]="selectable" [removable]="removable" (removed)="removeSoId(keyword)">
                                                    {{keyword}}
                                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                                </mat-chip>
                                                <input placeholder="Enter Service Order IDs..." [matChipInputFor]="soIdchipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                                                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addSoId($event)" (paste)="pasteChips($event,'soId')"/>
                                            </mat-chip-list>
                                        </mat-form-field>
                                    </div>
                             
                                    <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                                        <mat-form-field appearance="outline" class="custom-mat-form-field">
                                            <mat-chip-list #socIdchipList formControlName='socId'>
                                                <mat-chip *ngFor="let keyword of socIds" [selectable]="selectable" [removable]="removable" (removed)="removeSocId(keyword)">
                                                    {{keyword}}
                                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                                </mat-chip>
                                                <input placeholder="Enter Service Confirmation IDs..." [matChipInputFor]="socIdchipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                 [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addSocId($event)" (paste)="pasteChips($event,'socId')"/>
                                            </mat-chip-list>
                                        </mat-form-field>
                                    </div>
                                  
                                </form>

                            </div>
                            <footer class="text-center mt-5">
                                <button type="button" class="btn-light custom-theme-btn btn mr-2"  (click)="resetAdvanceFilterForm()">Reset</button>
                                <button type="button" class="custom-theme-btn btn btn-primary" (click)="filterConsolidateClaim()" data-dismiss="modal">Search</button>
                            </footer>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon class="material-icons-outlined mr-1">edit</mat-icon>
                                Edit Table
                            </ng-template>
                            <mat-form-field appearance="outline" class="custom-mat-form-field-dropdown">
                                <mat-label>Select Column</mat-label>
                                <mat-select #select [formControl]="columnFilterControl" multiple>
                                    <mat-select-trigger>
                                        <mat-chip-list>
                                            <mat-chip *ngFor="let data of columnFilterControl.value" [removable]="true" (removed)="onColumnRemoved(data)">
                                                {{ columnMappingsData[data] }}
                                                <mat-icon matChipRemove>cancel</mat-icon>
                                            </mat-chip>
                                        </mat-chip-list>
                                    </mat-select-trigger>
                                    <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection()" class="custom-mat-checkbox">Select All</mat-checkbox>
                                    <ng-container *ngFor="let data of columnsOption">
                                        <mat-option *ngIf="data.id != 'action' && data.id != 'consolidatedId'" (click)="optionClick()" [value]="data.id">{{data.value}}
                                        </mat-option>
                                    </ng-container>


                                </mat-select>
                            </mat-form-field>
                            <footer class="text-center mt-5">
                                <button type="button" class="btn-light custom-theme-btn btn mr-2" data-dismiss="modal">Cancel</button>
                                <button type="button" class="custom-theme-btn btn btn-primary" (click)="setUserColumnPref()" data-dismiss="modal">Save</button>
                            </footer>
                        </mat-tab>
                    </mat-tab-group>
                </div>

            </div>
        </div>
    </div>

    <!-- Print Modal -->
    <div class="modal fade bd-example-modal-lg custom-modal" id="printModel" tabindex="-1" role="dialog" aria-labelledby="addNoteModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title mb-0 float-left">Print Item</h2>
                    <h2 class="modal-title mb-0 float-right pointer" data-dismiss="modal">
                        <mat-icon>close</mat-icon>
                    </h2>
                </div>
                <div class="modal-body">
                    <div class="container-fluid" style="height: 50vh;">
                        <iframe  *ngIf="toBeDownloaded" [src]="downloadUrl | safe" style="height: inherit; width: inherit;">
            </iframe>
            <pdf-viewer *ngIf="!toBeDownloaded" [src]="pdfSrc"
              [render-text]="true"
              [original-size]="false"
              style="height: inherit; width: inherit;"></pdf-viewer>
                   </div>
                    <footer class="text-right mt-5">
                     
                        <button type="button" class="btn-light custom-theme-btn btn mr-2" data-dismiss="modal">Cancel </button>
                        <button type="button" class="custom-theme-btn btn btn-primary" (click)="print()">Print</button>
                    </footer>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade bd-example-modal-lg custom-modal" id="printErrorModel" tabindex="-1" role="dialog" aria-labelledby="addNoteModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title mb-0 float-left">Message</h2>
                    <h2 class="modal-title mb-0 float-right pointer" data-dismiss="modal">
                        <mat-icon>close</mat-icon>
                    </h2>
                </div>
                <div class="modal-body">
                    <div class="container-fluid" style="height: 10vh;">
                        No Document Found
                     </div>
                    <footer class="text-right mt-5">
                    
                        <button type="button" class="btn-light custom-theme-btn btn mr-2" data-dismiss="modal">Ok </button>
                     
                    </footer>
                </div>
            </div>
        </div>
    </div>
</div>