<div class="container-fluid mt-3" >
    <!-- *ngIf="userRole=='DM' || userRole=='AM'" -->
  <mat-card *ngIf='mainFilter' class="mainFilterCard">
      <div class="mainFilterHeading" >
        <h3>Main Filter</h3>
        <button type="button" class="btn btn-dark " (click)="cancelSearchAgain()">
            <mat-icon class="d-block"> close</mat-icon>
        </button>
     </div>
      <form [formGroup]="advancedSearchForm" class="row g-2 firstFilter">
        <div class="col-12 col-md-6 col-sm-6 col-lg-4" *ngIf="userRole=='SP'">
            <mat-form-field appearance="outline" class="custom-mat-form-field">
                <mat-label>Vendor</mat-label>
                <input matInput placeholder="Enter vendor" [value]="customerId" type="text" disabled>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6 col-sm-6 col-lg-4" *ngIf="userRole=='DM' || userRole=='AM'">
          <ng-multiselect-dropdown formControlName="districtsManagers" 
              [placeholder]="'District Managers*'"
              [settings]="dmDropdownSettings"
              [(ngModel)]="selectedDMs"
              (onSelect)="onMainDMDropDownClose()"
              [data]="dmList"
              (onDeSelect)="onDmDeSelect()"
              (onDeSelectAll)="onDmDeSelect()">
            </ng-multiselect-dropdown>
            <div class="rangeMsg">Please Select One District Manager.</div>

        </div>
        <div class="col-12 col-md-6 col-sm-6  col-lg-4" *ngIf="userRole=='DM' || userRole=='AM'">
            <ng-multiselect-dropdown formControlName="servicePartners"
            [placeholder]="'Service Providers*'"
            [settings]="spDropdownSettings"
            [data]="serviceProvidersList"
            [(ngModel)]="selectedSp" 
            (onDropDownClose)="onSPDropDownClose()"
            (onDeSelect)="onSpDeSelect()"
            >
            </ng-multiselect-dropdown>
        </div>
    <div class=" col-12 col-md-6 col-sm-6  col-lg-4">
        <ng-multiselect-dropdown formControlName="technicians"
            [placeholder]="'Technicians'"
            [settings]="technicianDropdownSettings"
            [data] ="techniciansList"
            [(ngModel)]="selectedTechnicians" 
          >
        </ng-multiselect-dropdown>
    </div>
    <div class="col-12 col-md-6 col-sm-6  col-lg-4">
        <ng-multiselect-dropdown formControlName="documentStatus" [placeholder]="'Status*'" [settings]="mainStatusDropdownSettings" [data]="editableSocs"
        [(ngModel)]="selectedStatus">
        </ng-multiselect-dropdown>
        <div class="rangeMsg">Please Select One Status.</div>

    </div>
    <div class="col-12 col-md-6 col-sm-6   col-lg-4">
        <ng-multiselect-dropdown formControlName="accountingIndicator" [placeholder]="'Accounting Indicator'" [settings]="accountIndDropdownSettings" [data]="masterData?.accountingIndicatorDetail">
        </ng-multiselect-dropdown>
    </div>
    <div class="col-12 col-md-6 col-sm-6  col-lg-4">
        <ng-multiselect-dropdown formControlName="serviceType" [placeholder]="'Service Type'" [settings]="servicetypeDropdownSettings" [data]="masterData?.serviceDetail">
        </ng-multiselect-dropdown>
    </div>

    <div class="col-12 col-md-6 col-sm-6  col-lg-4">
        <mat-form-field appearance="outline" class="custom-mat-form-field">
            <mat-label>Creation Date </mat-label>
            <mat-date-range-input #creationdateRangeInput [rangePicker]="creationdate">
                <input matStartDate formControlName='creationDateFrom'  placeholder="Start date" (dateChange)="onStartDateChange($event,creationdateRangeInput)">
                <input matEndDate formControlName='creationDateTo' placeholder="End date">
            </mat-date-range-input>
            <mat-icon matDatepickerToggleIcon class="date-clear" *ngIf="advancedSearchForm.get('creationDateFrom').value" (click)="clearDate($event,creationdateRangeInput)">clear</mat-icon>
            <mat-datepicker-toggle matSuffix [for]="creationdate"></mat-datepicker-toggle>
            <mat-date-range-picker #creationdate twoWayRangeSelect="30" ></mat-date-range-picker>
                        <!-- maxRange=30 -->
        </mat-form-field>

        <!-- <mat-form-field appearance="outline" class="custom-mat-form-field">
            <mat-label>Creation Date </mat-label>
            <mat-date-range-input  [rangePicker]="creationdate">
                <input matStartDate formControlName='creationDateFrom'  placeholder="Start date" (dateChange)="onStartDateChange($event,creationdateRangeInput)">
                <input matEndDate formControlName='creationDateTo' placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="creationdate"></mat-datepicker-toggle>
            <mat-date-range-picker #creationdate maxRange=30></mat-date-range-picker>
        </mat-form-field> -->

        <!-- <mat-form-field appearance="outline" class="custom-mat-form-field ">
            <mat-label>Creation Date </mat-label>
            <input  matInput autocomplete="off"
                #creationDatePicker
                ngxDaterangepickerMd
                [formControl]="advancedSearchForm.controls['creationDate']"
                [showCustomRangeLabel]="true"
                [alwaysShowCalendars]="true"
                [ranges]="ranges"
                [linkedCalendars]="true"
                [showClearButton]="true"
                [dateLimit]="29"
                [maxDate]="maxDate"
                placeholder="Creation Date" readonly/>
        </mat-form-field> -->
        <div class="rangeMsg">Max Date Range 30 Days.</div>
    </div>
    <div class="col-12 col-md-6 col-sm-6  col-lg-4 custom-picker-right">
        <mat-form-field appearance="outline" class="custom-mat-form-field">
            <mat-label>Appointment Date </mat-label>
            <mat-date-range-input #appointmentdateRangeInput [rangePicker]="appointmentdate" >
                <input matStartDate formControlName='appointmentDateFrom' placeholder="Start date" (dateChange)="onStartDateChange($event,appointmentdateRangeInput)">
                <input matEndDate formControlName='appointmentDateTo' placeholder="End date">
            </mat-date-range-input>
            <mat-icon matDatepickerToggleIcon class="date-clear" *ngIf="advancedSearchForm.get('appointmentDateFrom').value" (click)="clearDate($event,appointmentdateRangeInput)">clear</mat-icon>
            <mat-datepicker-toggle matSuffix [for]="appointmentdate"></mat-datepicker-toggle>
            <mat-date-range-picker #appointmentdate twoWayRangeSelect="30"></mat-date-range-picker>
        </mat-form-field>

        <!-- <mat-form-field appearance="outline" class="custom-mat-form-field">
            <input  matInput autocomplete="off"
                #appointmentDatePicker
                ngxDaterangepickerMd
                [formControl]="advancedSearchForm.controls['appointmentDate']"
                [showCustomRangeLabel]="true"
                [alwaysShowCalendars]="true"
                [ranges]="ranges"
                [linkedCalendars]="true"
                [showClearButton]="true"
                [dateLimit]="29"
                [maxDate]="maxDate"
                placeholder="Appointment Date" readonly/>
        </mat-form-field> -->

        <div class="rangeMsg">Max Date Range 30 Days.</div>

    </div>

    <div class="col-12 col-md-6 col-sm-6  col-lg-4 custom-picker-left">
        <mat-form-field appearance="outline" class="custom-mat-form-field">
            <mat-label>Call Date </mat-label>
            <mat-date-range-input #calldateRangeInput [rangePicker]="calldate" >
                <input matStartDate formControlName='callDateFrom' placeholder="Start date" (dateChange)="onStartDateChange($event,calldateRangeInput)">
                <input matEndDate formControlName='callDateTo' placeholder="End date">
            </mat-date-range-input>
            <mat-icon matDatepickerToggleIcon class="date-clear" *ngIf="advancedSearchForm.get('callDateFrom').value" (click)="clearDate($event,calldateRangeInput)">clear</mat-icon>
            <mat-datepicker-toggle matSuffix [for]="calldate"></mat-datepicker-toggle>
            <mat-date-range-picker #calldate twoWayRangeSelect="30"></mat-date-range-picker>
        </mat-form-field>

        <!-- <mat-form-field appearance="outline" class="custom-mat-form-field">
            <input  matInput autocomplete="off"
                 #callDatePicker
                ngxDaterangepickerMd
                [formControl]="advancedSearchForm.controls['callDate']"
                [showCustomRangeLabel]="true"
                [alwaysShowCalendars]="true"
                [ranges]="ranges"
                [linkedCalendars]="true"
                [showClearButton]="true"
                [dateLimit]="29"
                [maxDate]="maxDate"
                placeholder="Call Date" readonly/>
        </mat-form-field> -->

        <div class="rangeMsg">Max Date Range 30 Days.</div>
    </div>

    <div class="col-12 col-md-6 col-sm-6  col-lg-4">
        <mat-form-field appearance="outline" class="custom-mat-form-field">
            <mat-chip-list #socNumberChipList formControlName='documentNumber'>
                <mat-chip *ngFor="let keyword of socNumbers" [selectable]="selectable" [removable]="removable" (removed)="removeSocNumbers(keyword)">
                    {{keyword}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Enter Service Confirmation IDs..." [matChipInputFor]="socNumberChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addSocNumbers($event)" (paste)="pasteChips($event,'socId')"/>
            </mat-chip-list>
        </mat-form-field>
    </div>
    <div class="col-12 col-md-6 col-sm-6  col-lg-4">
        <mat-form-field appearance="outline" class="custom-mat-form-field">
            <mat-chip-list #soNumberChipList formControlName='externalReference'>
                <mat-chip *ngFor="let keyword of soNumbers" [selectable]="selectable" [removable]="removable" (removed)="removeSoNumbers(keyword)">
                    {{keyword}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Enter Service Order IDs..." [matChipInputFor]="soNumberChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addSoNumbers($event)" (paste)="pasteChips($event,'soId')"/>
            </mat-chip-list>
        </mat-form-field>
      </div>


    <div class="text-center mt-5 w-100">
        <button type="button" class="btn-light custom-theme-btn btn mr-2" (click)="resetMainFilterForm()">Reset</button>
        <button type="submit" class="custom-theme-btn btn btn-primary" [disabled]="(userRole!='SP' && socNumbers.length==0 && soNumbers.length==0 && !advancedSearchForm.valid) || !advancedSearchForm.get('documentStatus').value" (click)="mainFilterSearch()">Search</button>
    </div>
    </form>
    </mat-card>
</div>

<div class="container-fluid" *ngIf='!mainFilter'>
    <div class="table-actions">
        <input class="form-control mr-1 searchInput" (keyup)="filterSOcs($event)" placeholder="Filter Results..." #input>
        <div class="btn-action mr-1" style="color: white;" >
            <!-- *ngIf="userRole=='DM' || userRole=='AM'" -->
            <button mat-stroked-button class="custom-theme-btn btn btn-primary searchAgainBtn" (click)="searchAgain()">
              <mat-icon>search</mat-icon> Search Again
            </button>
        </div>
        <div class="btn-action mr-1" style="color: white;" >
            <button mat-stroked-button class="btn btn-primary border-0 font-weight-normal" (click)="exportToSheet()"> 
                <mat-icon>file_download</mat-icon> Download
            </button>
        </div>

        <button mat-mini-fab matTooltip="Filters" matTooltipClass="tooltipClass" color="primary" 
        data-toggle="modal" data-target="#exampleModalCenter"  (click)="initialSelect()">
            <mat-icon>filter_alt</mat-icon>
        </button>

        <!-- <button mat-mini-fab matTooltip="Filters" matTooltipClass="tooltipClass" color="primary" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>filter_list</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button data-toggle="modal" data-target="#exampleModalCenter" mat-menu-item (click)="initialSelect()"> 
                <mat-icon>filter_alt</mat-icon> {{userRole=='SP'?'Search':'Advance Filter'}}
            </button>
            <button mat-menu-item (click)="exportToSheet()"> 
                <mat-icon>description</mat-icon> Download Sheet
            </button>
        </mat-menu> -->

    </div>

    <section class="example-container mat-elevation-z8">
        <div class="custom-table">
            <mat-table #table [dataSource]="dataSource" matSort class="table mb-0">
                <ng-container [matColumnDef]="column.id" *ngFor="let column of displayedColumns">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{column.value}} </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="w-100">
                        <span class="mobile-label w-100">{{column.value}}:</span>
                        <span class="w-100 pl-3" *ngIf="column.id =='socId'"><a [routerLink]="['/home/soc-details',element.socId]" [queryParams]="{for: 'claim'}" (click)="selectSoc(element)">{{element[column.id]}}</a></span>
                        <span class="w-100 pl-3" *ngIf="column.id.includes('Date')">{{element[column.id] | date: 'yyyy-MM-dd'}}</span>
                        <span class="w-100 pl-3" *ngIf="column.id !='socId' && !column.id.includes('Date')">{{element[column.id]}}</span>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky:true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="selectSoc(row)"></mat-row>
            </mat-table>
            <table class="w-100">
                <tr *ngIf="isTableDataSet && !dataSource.data?.length">
                    <td style="text-align: center;" [attr.colspan]="displayedColumns?.length">
                    No Records Found!
                    </td>
                </tr>
            </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10,20,25]" [showFirstLastButtons]="true">></mat-paginator>
    </section>
</div>


<!-- Modal -->
<div class="modal fade bd-example-modal-lg custom-modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title mb-0 float-left" id="exampleModalLongTitle">Detailed Search</h2>
                <h2 class="modal-title mb-0 float-right" style="cursor: pointer" data-dismiss="modal">
                    <mat-icon>close</mat-icon>
                </h2>
            </div>
            <div class="modal-body">
                <mat-tab-group dynamicHeight>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="example-tab-icon mr-1">filter_alt</mat-icon>
                            <!-- {{userRole=='SP'?'Search':'Advance Filter'}} -->
                            Advance Filter
                        </ng-template>

                        <div class="container-fluid">

                            <form [formGroup]="advancedSearchForm" class="row g-2">
                                <div class="col-12 col-md-6 col-sm-6 col-lg-4" *ngIf="userRole=='SP'">
                                    <mat-form-field appearance="outline" class="custom-mat-form-field">
                                        <mat-label>Vendor</mat-label>
                                        <input matInput placeholder="Enter vendor" [value]="customerId" type="text" disabled>
                                    </mat-form-field>
                                </div>
                              
                                <div class="col-12 col-md-6 col-sm-6 col-lg-4"*ngIf="userRole=='DM' || userRole=='AM'">
                                    <ng-multiselect-dropdown [formControl]="advancedSearchForm.controls['districtsManagers']"
                                        [placeholder]="'District Managers*'"
                                        [settings]="dmDropdownSettings"
                                        [(ngModel)]="selectedAdvancedFilterDMs"
                                        (onSelect)="onDMDropDownClose()"
                                        [data]="selectedDMs"
                                        [disabled]="selectedAdvancedFilterDMs?true:false"
                                        >
                                    </ng-multiselect-dropdown>
                                </div>
                                <div class="col-12 col-md-6 col-sm-6  col-lg-4" *ngIf="userRole=='DM' || userRole=='AM'">
                                  <ng-multiselect-dropdown [formControl]="advancedSearchForm.controls['servicePartners']" 
                                        [placeholder]="'Service Providers*'"
                                        [settings]="spDropdownSettings"
                                        [data]="selectedSp"
                                        [(ngModel)]="selectedAdvancedFilterSp" 
                                        (onDropDownClose)="onSPDropDownClose()">
                                    </ng-multiselect-dropdown>
                              </div>

                              <div class=" col-12 col-md-6 col-sm-6  col-lg-4" >
                                <!-- *ngIf="userRole=='DM' || userRole=='AM'" -->
                                  <ng-multiselect-dropdown [formControl]="advancedSearchForm.controls['technicians']" 
                                      [placeholder]="'Technicians'"
                                      [settings]="technicianDropdownSettings"
                                      [data] ="selectedTechnicians?.length>0?selectedTechnicians:techniciansList"
                                      [(ngModel)]="selectedAdvancedFilterTechnicians">
                                  </ng-multiselect-dropdown>
                              </div>

                              <div class=" col-12 col-md-6 col-sm-6  col-lg-4">
                                <ng-multiselect-dropdown [formControl]="advancedSearchForm.controls['documentStatus']"  [placeholder]="'Status'" [settings]="mainStatusDropdownSettings" [data]="advanceFilterStatusList"
                                [(ngModel)]="selectedStatus">
                                </ng-multiselect-dropdown>
                             </div>
                             
                             <div class="col-12 col-md-6 col-sm-6   col-lg-4">
                                 <ng-multiselect-dropdown [formControl]="advancedSearchForm.controls['accountingIndicator']"  [placeholder]="'Accounting Indicator'" [settings]="accountIndDropdownSettings" [data]="advanceFilterAccountingIndicatorList">
                                 </ng-multiselect-dropdown>
                             </div>
                             <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                                 <ng-multiselect-dropdown [formControl]="advancedSearchForm.controls['serviceType']" [placeholder]="'Service Type'" [settings]="servicetypeDropdownSettings" [data]="advanceFilterServiceTypeList">
                                 </ng-multiselect-dropdown>
                             </div>
                              <div class="col-12 col-md-6 col-sm-6  col-lg-4" *ngIf="userRole!='SP'">
                                <ng-multiselect-dropdown [formControl]="advancedSearchForm.controls['documentType']" [placeholder]="'Document Type'" [settings]="documentTypeDropdownSettings" [data]="advanceFilterDocumentTypeList">
                                </ng-multiselect-dropdown>
                             </div>


                            <div class="col-12 col-md-6 col-sm-6  col-lg-4 custom-picker-right">
                                <mat-form-field appearance="outline" class="custom-mat-form-field">
                                    <mat-label>Creation Date </mat-label>
                                    <mat-date-range-input #creationdateRangeInput [rangePicker]="creationdate">
                                        <input matStartDate formControlName='creationDateFrom'  placeholder="Start date" (dateChange)="onStartDateChange($event,creationdateRangeInput)">
                                        <input matEndDate formControlName='creationDateTo' placeholder="End date">
                                    </mat-date-range-input>
                                    <mat-icon matDatepickerToggleIcon class="date-clear" *ngIf="advancedSearchForm.get('creationDateFrom').value" (click)="clearDate($event,creationdateRangeInput)">clear</mat-icon>
                                    <mat-datepicker-toggle matSuffix [for]="creationdate"></mat-datepicker-toggle>
                                    <mat-date-range-picker #creationdate twoWayRangeSelect="30"></mat-date-range-picker>
                                </mat-form-field>

                                <!-- <mat-form-field appearance="outline" class="custom-mat-form-field">
                                    <input  matInput autocomplete="off"
                                        #creationDatePicker
                                        ngxDaterangepickerMd
                                        [formControl]="advancedSearchForm.controls['creationDate']"
                                        [showCustomRangeLabel]="true"
                                        [alwaysShowCalendars]="true"
                                        [ranges]="ranges"
                                        [linkedCalendars]="true"
                                        [showClearButton]="true"
                                        [dateLimit]="29"
                                        [maxDate]="maxDate"
                                        placeholder="Creation Date" readonly/>
                                </mat-form-field> -->
                                <div class="rangeMsg">Max Date Range 30 Days.</div>
                            </div>
                          
                            <div class="col-12 col-md-6 col-sm-6  col-lg-4 custom-picker-left">
                                <mat-form-field appearance="outline" class="custom-mat-form-field">
                                    <mat-label>Appointment Date </mat-label>
                                    <mat-date-range-input #appointmentdateRangeInput [rangePicker]="appointmentdate" >
                                        <input matStartDate formControlName='appointmentDateFrom' placeholder="Start date" (dateChange)="onStartDateChange($event,appointmentdateRangeInput)">
                                        <input matEndDate formControlName='appointmentDateTo' placeholder="End date">
                                    </mat-date-range-input>
                                    <mat-icon matDatepickerToggleIcon class="date-clear" *ngIf="advancedSearchForm.get('appointmentDateFrom').value" (click)="clearDate($event,appointmentdateRangeInput)">clear</mat-icon>
                                    <mat-datepicker-toggle matSuffix [for]="appointmentdate"></mat-datepicker-toggle>
                                    <mat-date-range-picker #appointmentdate twoWayRangeSelect="30"></mat-date-range-picker>
                                </mat-form-field>

                                <!-- <mat-form-field appearance="outline" class="custom-mat-form-field">
                                    <input  matInput autocomplete="off"
                                        #appointmentDatePicker
                                        ngxDaterangepickerMd
                                        [formControl]="advancedSearchForm.controls['appointmentDate']"
                                        [showCustomRangeLabel]="true"
                                        [alwaysShowCalendars]="true"
                                        [ranges]="ranges"
                                        [linkedCalendars]="true"
                                        [showClearButton]="true"
                                        [dateLimit]="29"
                                        [maxDate]="maxDate"
                                        placeholder="Appointment Date" readonly/>
                                </mat-form-field> -->
                                <div class="rangeMsg">Max Date Range 30 Days.</div>
                        
                            </div>
                        
                            <div class="col-12 col-md-6 col-sm-6  col-lg-4 custom-picker-right">
                                <mat-form-field appearance="outline" class="custom-mat-form-field">
                                    <mat-label>Call Date </mat-label>
                                    <mat-date-range-input #calldateRangeInput [rangePicker]="calldate" >
                                        <input matStartDate formControlName='callDateFrom' placeholder="Start date" (dateChange)="onStartDateChange($event,calldateRangeInput)">
                                        <input matEndDate formControlName='callDateTo' placeholder="End date">
                                    </mat-date-range-input>
                                    <mat-icon matDatepickerToggleIcon class="date-clear" *ngIf="advancedSearchForm.get('callDateFrom').value" (click)="clearDate($event,calldateRangeInput)">clear</mat-icon>
                                    <mat-datepicker-toggle matSuffix [for]="calldate"></mat-datepicker-toggle>
                                    <mat-date-range-picker #calldate twoWayRangeSelect="30"></mat-date-range-picker>
                                </mat-form-field>


                                <!-- <mat-form-field appearance="outline" class="custom-mat-form-field">
                                    <input  matInput autocomplete="off"
                                        #callDatePicker
                                        ngxDaterangepickerMd
                                        [formControl]="advancedSearchForm.controls['callDate']"
                                        [showCustomRangeLabel]="true"
                                        [alwaysShowCalendars]="true"
                                        [ranges]="ranges"
                                        [linkedCalendars]="true"
                                        [showClearButton]="true"
                                        [dateLimit]="29"
                                        [maxDate]="maxDate"
                                        placeholder="Call Date" readonly/>
                                </mat-form-field> -->
                                <div class="rangeMsg">Max Date Range 30 Days.</div>
                        
                            </div>

                              
                                <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                                  <mat-form-field appearance="outline" class="custom-mat-form-field">
                                      <mat-chip-list #socNumberChipList formControlName='documentNumber'>
                                          <mat-chip *ngFor="let keyword of socNumbers" [selectable]="selectable" [removable]="removable" (removed)="removeSocNumbers(keyword)">
                                              {{keyword}}
                                              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                          </mat-chip>
                                          <input placeholder="Enter Service Confirmation IDs..." [matChipInputFor]="socNumberChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                                          [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addSocNumbers($event)" (paste)="pasteChips($event,'socId')"/>
                                      </mat-chip-list>
                                    </mat-form-field>
                                </div>


                              
                                <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                                  <mat-form-field appearance="outline" class="custom-mat-form-field">
                                      <mat-chip-list #soNumberChipList formControlName='externalReference'>
                                          <mat-chip *ngFor="let keyword of soNumbers" [selectable]="selectable" [removable]="removable" (removed)="removeSoNumbers(keyword)" >
                                              {{keyword}}
                                              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                          </mat-chip>
                                          <input placeholder="Enter Service Order IDs..." [matChipInputFor]="soNumberChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                                          [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addSoNumbers($event)" (paste)="pasteChips($event,'soId')"/>
                                      </mat-chip-list>
                                  </mat-form-field>
                                </div>

                     
                                <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                                  <mat-form-field appearance="outline" class="custom-mat-form-field">
                                      <mat-chip-list #customerNumberChipList formControlName='customerNumber'>
                                          <mat-chip *ngFor="let keyword of customerNumbers" [selectable]="selectable" [removable]="removable" (removed)="removeCustomerNumbers(keyword)">
                                              {{keyword}}
                                              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                          </mat-chip>
                                          <input placeholder="Enter Customer IDs..." [matChipInputFor]="customerNumberChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                           [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addCustomerNumbers($event)" (paste)="pasteChips($event,'customerNo')"/>
                                      </mat-chip-list>
                                  </mat-form-field>
                                </div>
                            </form>

                        </div>
                        <footer class="text-center mt-5">
                            <button type="button" class="btn-light custom-theme-btn btn mr-2" (click)="resetAdvanceFilterForm()">Reset</button>
                            <button type="submit" class="custom-theme-btn btn btn-primary" [disabled]="userRole!='SP' && socNumbers.length==0 && soNumbers.length==0 && (!selectedAdvancedFilterSp || selectedAdvancedFilterSp.length<=0 || !selectedAdvancedFilterDMs || selectedAdvancedFilterDMs.length<=0 )"
                            (click)="onAdvancedFilterSubmit()" data-dismiss="modal">Search</button>
                        </footer>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="material-icons-outlined mr-1">edit</mat-icon>
                            Edit Table
                        </ng-template>
                        <mat-form-field appearance="outline" class="custom-mat-form-field-dropdown">
                            <mat-label>Select Column</mat-label>
                            <mat-select #select [formControl]="columnFilterControl" multiple>
                                <mat-select-trigger>
                                    <mat-chip-list>
                                        <mat-chip *ngFor="let data of columnFilterControl.value" [removable]="true" (removed)="onColumnRemoved(data)">
                                            {{ columnMappingsData[data] }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                </mat-select-trigger>
                                <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection()" class="custom-mat-checkbox">Select All</mat-checkbox>
                                <ng-container *ngFor="let data of columnsOption">
                                    <mat-option *ngIf="data.id!='socId'" (click)="optionClick()" [value]="data.id">{{data.value}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <footer class="text-center mt-5">
                            <button type="button" class="btn-light custom-theme-btn btn mr-2" data-dismiss="modal">Cancel</button>
                            <button type="button" class="custom-theme-btn btn btn-primary" (click)="setUserColumnPref()" data-dismiss="modal">Save</button>
                        </footer>
                    </mat-tab>
                </mat-tab-group>
            </div>

        </div>
    </div>
</div>