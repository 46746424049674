<!-- <div class="wrapper">
    <mat-sidenav-container class="example-container" autosize>
        <mat-sidenav #drawer class="example-sidenav" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false" fullscreen>
            <app-side-nav *ngIf="sidenav && !isLoginPage"></app-side-nav>
        </mat-sidenav>
        <mat-sidenav-content class="example-sidenav-content">

            <header class="header" *ngIf="!isLoginPage">
                <app-header *ngIf="header " (SideNavToggle)="drawer.toggle()"></app-header>
            </header>
            <main [ngClass]="{'main': !isLoginPage}">
                <router-outlet></router-outlet>
            </main>
       
        </mat-sidenav-content>
    </mat-sidenav-container>

</div> -->

<router-outlet></router-outlet>
<ngx-ui-loader bgsColor='#eaeaea' fgsColor='#f4f5fa'></ngx-ui-loader>
<simple-notifications [options]="options"></simple-notifications>
