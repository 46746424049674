import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { Router } from "@angular/router";
import {APPCONFIG} from '../shared/config';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public url:any = APPCONFIG.URL+"/_ah/api";
  userToken:any='';
  userId: any=''
  constructor(private httpClient:HttpClient,private router: Router) {
  
   }

  loginHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey,
    'key':APPCONFIG.key,'language':APPCONFIG.language
  })
  };

  createHTTPHeader(){
    let userId:any = localStorage.getItem('userId');
    let userToken:any = localStorage.getItem('userToken');
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','apiKey':APPCONFIG.apiKey,
      'key':APPCONFIG.key, 'authentication': userToken,'userId': userId,'language':APPCONFIG.language,
    })
    };
    return httpOptions;
  }
  get(url:string): Observable<any> {
    let httpOptions = this.createHTTPHeader();
    return this.httpClient.get(this.url +"/"+url,httpOptions)
  }


  post(url:string,data?:any): Observable<any> {
    let httpOptions = this.createHTTPHeader();
    return this.httpClient.post(this.url +"/"+url,data,httpOptions)
  }

  put(url:string,data:any): Observable<any>{
    let httpOptions = this.createHTTPHeader();
    return this.httpClient.put(this.url +"/"+url,data, httpOptions)
  }

  delete(url:string): Observable<any> {
    let httpOptions = this.createHTTPHeader();
    return this.httpClient.delete(this.url +"/"+url, httpOptions)
  }

  loginRequest(url:string,data: Object) : Observable<any> {
    let userToken:any = localStorage.getItem('userToken');
    let httpOptions = {
       headers : new Headers({'authentication': userToken})
    };
    return this.httpClient.post(this.url +"/"+url,data,this.loginHttpOptions)
}

}

