import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from '../alert-dialog/alert.service';
import { AppService } from '../services/app.service';
import { CommonService } from '../services/common.service';
import { DataShareService } from '../services/data-share.service';
import { HttpService } from '../services/http.service';
import { PreviousRouteService } from '../services/prevoiusRoute.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormBuilder, Validators } from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  @ViewChild(MatSort, { static: true })
  sort!: MatSort;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  userId: string | null;
  userRole: string | null;
  customerId: string | null;
  dataSource : MatTableDataSource<any>;
  mainFilter = false;
  columnsToDisplay: string[] = ['type','message','userName', 'createdAt','responseTime','action'];
  data:any =[];
  pageSize = 25;
  pageSizeOptions = [10,25,50,100];
  currentPage: any=0;
  pageEvent: any;
  // length=10000;
  advancedSearchForm: any;
  dropdownSettings:any;
  isTableDataSet:boolean=false;
  // selectedLogTypes:any=[];
  // logTypesData:any;
  // timeFormat:number=24;

// darkTheme: NgxMaterialTimepickerTheme = {
//       container: {
//           buttonColor: '#062f60'
//       },
//       dial: {
//           dialBackgroundColor: '#062f60',
//       },
//       clockFace: {
//           clockHandColor: '#062f60',
//           clockFaceTimeInactiveColor: '#062f60'
//       }
//     };
  @ViewChild('logdateRangeInput') logdateRangeInput: ElementRef | any;
    logStartDate=new Date();
    logEndDate=new Date();

  constructor(private appService: AppService, private formBuilder: FormBuilder,
    private router: Router,private previousRouteService: PreviousRouteService,private commonService:CommonService,private alertService: AlertService,
    private httpService:HttpService,public matDialog: MatDialog,private loaderService: NgxUiLoaderService, private dataShareService:DataShareService) { 
    this.appService.setTitle('Logs');
    this.userId = this.dataShareService.getLocalStorage('userId');
    this.userRole = this.dataShareService.getLocalStorage('role');
    this.customerId=  this.dataShareService.getLocalStorage('customerNumber');
    this.dataSource = new MatTableDataSource();
    // console.log(this.dataShareService.getFilterSocRequestParameter())
    // this.appService.getClaimManagementClicked().subscribe((claimManagementClicked:any) => this.claimManagementClicked = claimManagementClicked);
    if(this.userRole!='AM')  {
        this.router.navigate(['home/claim-management'])
        return;
      }
      this.logStartDate.setDate(this.logStartDate.getDate() - 7);
      // this.logEndDate.setDate(this.logEndDate.getDate() + 7);
      this.advancedSearchForm = this.formBuilder.group({
        logTypes:[ ''],
        users:[''],
        logStartDate:[this.logStartDate,Validators.required],
        logEndDate:[this.logEndDate,Validators.required],
        logStartTime:[''],
        logEndTime:['']

      });
    }
  ngOnInit(): void {
    // this.fetchLogs(this.pageSize,this.currentPage);
    this.getLogs(true);

    this.dropdownSettings = {
      singleSelection: false,
      idField: '',
      textField: '',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText:'Please Select'
    };

  }
  ngAfterViewInit() {
    // console.log(this.creationdateRangeInput)
    this.setDefaultMaxDate();
  }
  setDefaultMaxDate(){
    setTimeout(()=>{
      console.log(this.logdateRangeInput)
      this.logdateRangeInput.max=new Date();
      this.logdateRangeInput.min=''
    })
  }
  // fetchLogs(size: string | number,currentPage: string | number){
  //   // var userData= this.userId;
  //   // if(this.userRole == 'AM'){
  //   //   userData='All'
  //   // }
  //   // this.loaderService.start();
  //   // this.httpService.get('logging/v1/fetchAllLogs?user='+userData+"&offset="+currentPage+"&total="+size).subscribe(
  //   //   res => {
  //   //     this.data=res;
  //   //     this.loaderService.stop();
  //   //    if( this.data.length==0){
  //   //     this.alertService.openErrorDialogBox('No logs available at this moment!')
  //   //     return;
  //   //    }
  //   //     if(this.data){
  //   //       this.dataSource = new MatTableDataSource(this.data);
  //   //       this.dataSort()
  //   //     }else {
  //   //       this.alertService.openErrorDialogBox('Something went wrong. Please try again or contact admin!')
  //   //     }
  //   //   },
  //   //   err => {
  //   //     // console.log("error occured while fetching appliances");
  //   //     this.loaderService.stop();
  //   //     this.alertService.openErrorDialogBox('Something went wrong. Please try again or contact admin!')
  //   //   }
  //   // );
  // }
  userIds:any;
  logTypes:any;
  getLogs(isFilter:boolean){
    this.loaderService.start();
    this.isTableDataSet=false;
    var userData= this.userId;
    let formData = this.advancedSearchForm.getRawValue();
    console.log(formData);
    let data={
        "startDate":formData.logStartDate?moment(formData.logStartDate.toString()).format('YYYY-MM-DD'):'',
        "endDate":formData.logEndDate?moment(formData.logEndDate.toString()).format('YYYY-MM-DD'):'',
        "logType":formData.logTypes?formData.logTypes:[],
        "userId":formData.users?formData.users:[],
        "count":this.pageSize.toString(),
        "offset":(this.currentPage*this.pageSize).toString(),
        "tableId":(!isFilter&&this.data&&this.data.tableId)?this.data.tableId:"",
        "datasetId":(!isFilter&&this.data&&this.data.dataSetId)?this.data.dataSetId:"",
        "sortBy":"",
        filterRequired: isFilter
      }
    this.httpService.post('logging/v1/fetchAllLogs',data).subscribe(
      res => {
        this.isTableDataSet=true;
        this.data=res;
        this.loaderService.stop();
       if( this.data.items && this.data.items.length==0){
        // this.alertService.openErrorDialogBox('No logs available at this moment!')
        this.dataSource = new MatTableDataSource(this.data.items);
        this.dataSort()
        return;
       }
        if(this.data.items){
          this.dataSource = new MatTableDataSource(this.data.items);
          this.dataSort();
          if(isFilter){
            this.userIds = this.data.userIdFilterList;
            this.logTypes = this.data.logTypeFilterList
          }
        }else if(this.data.status=="ERROR") {
          this.alertService.openErrorDialogBox(this.data.errorMessage)
        }
       
      },
      err => {
        this.isTableDataSet=false;
        // console.log("error occured while fetching appliances");
        this.loaderService.stop();
        this.alertService.openErrorDialogBox('Something went wrong. Please try again or contact admin!')
      }
    );

  }


  dataSort() {
    this.dataSource.sort = this.sort;
 //   this.dataSource.paginator = this.paginator;
  }

  copyText(val: string){
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
  }
  filterlogs(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public handlePage(e: any) {
    console.log(e)
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    // this.fetchLogs(this.pageSize,this.currentPage)
    this.getLogs(false)
  }
  clearDate(event:any,rangeInput:any){
    this.advancedSearchForm.get([rangeInput._endInput.ngControl.name]).setValue('');
    this.advancedSearchForm.get([rangeInput._startInput.ngControl.name]).setValue('');
    rangeInput.max=new Date();
    rangeInput.min='';
  }
  onAdvancedFilterSubmit(){
    this.getLogs(true)
  }
  reset(){
    this.advancedSearchForm.reset();
    this.setDefaultMaxDate();

  }
  onStartDateChange(startDate:any,rangeInput:any){
    var date:any='';
    let minDate:any;
    let maxDate:any='';
    let selectedStartDate:any='';
    if(startDate.value){
      selectedStartDate = new Date(startDate.value);
      selectedStartDate.setDate(selectedStartDate.getDate() - 179);      
      minDate = selectedStartDate;
      date= new Date(startDate.value);
      date= new Date(startDate.value);
      date.setDate(date.getDate() + 179);
      if(date > new Date()){
        maxDate = new Date();
      }else{
        maxDate = date;
      }
    }
    rangeInput.max=maxDate;
    rangeInput.min=minDate;
  }
}
