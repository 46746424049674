<mat-form-field  appearance="outline"class="custom-mat-form-field">
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let keyword of keywords" [selectable]="selectable"
               [removable]="removable" (removed)="remove(keyword)">
              {{keyword}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input [placeholder]="placeholder"
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="addOnBlur"
             (matChipInputTokenEnd)="add($event)"/>
    </mat-chip-list>
</mat-form-field>
