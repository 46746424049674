<div class="container-fluid " >
    <div class="table-actions">
        <input class="form-control mr-1 searchInput" (keyup)="filterlogs($event)" placeholder="Search here.." #input>
    
        <div class="date-toggle">
           
            <button mat-mini-fab matTooltip="Filters" matTooltipClass="tooltipClass" color="primary" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>filter_list</mat-icon>
        </button>
        </div>

        <mat-menu #menu="matMenu">
            <button data-toggle="modal" data-target="#exampleModalCenter" mat-menu-item >
                <mat-icon>filter_alt</mat-icon> Advance Filter
            </button>
        </mat-menu>

    </div>



    <section class="example-container mat-elevation-z8">
        <div class="custom-table">
            <mat-table #table [dataSource]="dataSource" matSort class="table mb-0">
                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell class="header-logs" *matHeaderCellDef mat-sort-header>Timestamp</th>
                    <td mat-cell *matCellDef="let element"> {{element.createdAt | date: 'dd/MM/yyyy-HH:mm:ss'}} 
                        <span  *ngIf="!element.createdAt">Not available</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="message">
                    <th mat-header-cell class="header-logs" *matHeaderCellDef mat-sort-header>Messages</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.show ? element.message : (element.message | slice:0:30) + '...'}}
                        <button *ngIf="element.show" (click)="element.show = false;" mat-button class="blue-color" type="button">hide</button>
                        <button *ngIf="!element.show" type="button" (click)="element.show = true;" mat-button class="blue-color">show</button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell  class="header-logs" *matHeaderCellDef mat-sort-header>Type</th>
                    <td mat-cell *matCellDef="let element">{{element.type ? element.type : 'Not available'}} </td>
                </ng-container>
                <ng-container matColumnDef="userName">
                    <th mat-header-cell  class="header-logs" *matHeaderCellDef mat-sort-header>User Name</th>
                    <td mat-cell *matCellDef="let element">{{element.userName ? element.userName : 'Not available'}} </td>
                </ng-container>
                <ng-container matColumnDef="responseTime">
                    <th mat-header-cell  class="header-logs" *matHeaderCellDef mat-sort-header>Response Time (sec)</th>
                    <td mat-cell *matCellDef="let element">{{element.responseTime ? element.responseTime : '-'}} </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell class="header-logs" *matHeaderCellDef mat-sort-header>Action</th>
                    <td mat-cell *matCellDef="let element">
                    <button matTooltip="Copy" style="cursor:pointer;" tittle="copy" (click)="copyText(element.message)" class="blue-color" mat-button  id="remove-Row">
                    <mat-icon>file_copy</mat-icon>
                  </button>
                </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
            </mat-table>
            <table class="w-100">
                <tr *ngIf="isTableDataSet && !dataSource.data?.length">
                    <td style="text-align: center;">
                    No Records Found!
                    </td>
                </tr>
            </table>
           
        </div>
        <mat-paginator #paginator
        [pageSize]="pageSize"
        [length]="data?.totalRows"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = handlePage($event)"
        [showFirstLastButtons]="true">></mat-paginator>
    </section>
</div>

<!-- advanced filter Modal -->
<div class="modal fade bd-example-modal-lg custom-modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title mb-0" id="exampleModalLongTitle">Detailed Search</h2>
                <h2 class="modal-title mb-0 float-right" style="cursor: pointer" data-dismiss="modal">
                    <mat-icon>close</mat-icon>
                </h2>
            </div>
            <div class="modal-body">
        
                        <ng-template mat-tab-label>
                            <mat-icon class="example-tab-icon mr-1">filter_alt</mat-icon>
                            Advance Filter
                        </ng-template>
                        <div class="container-fluid">
                            <form [formGroup]="advancedSearchForm" class="row g-2">
                                <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                                    <ng-multiselect-dropdown formControlName="logTypes"
                                      [placeholder]="'Log Type'"
                                      [settings]="dropdownSettings"
                                      [data]="logTypes"
                                      >
                                  </ng-multiselect-dropdown>
                                </div>
                                <div class="col-12 col-md-6 col-sm-6  col-lg-4">
                                    <ng-multiselect-dropdown formControlName="users"
                                      [placeholder]="'User Name'"
                                      [settings]="dropdownSettings"
                                      [data]="userIds"
                                      >
                                  </ng-multiselect-dropdown>
                                </div>

                                <div class="col-12 col-md-6 col-sm-6  col-lg-4 custom-picker-right">
                                    <mat-form-field appearance="outline" class="custom-mat-form-field">
                                        <mat-label>Time Stamp* </mat-label>
                                        <mat-date-range-input #logdateRangeInput [rangePicker]="logdate" >
                                            <input matStartDate formControlName='logStartDate' placeholder="Start date" (dateChange)="onStartDateChange($event,logdateRangeInput)">
                                            <input matEndDate formControlName='logEndDate' placeholder="End date">
                                        </mat-date-range-input>
                                        <mat-icon matDatepickerToggleIcon class="date-clear" *ngIf="advancedSearchForm.get('logStartDate').value"(click)="clearDate($event,logdateRangeInput)" >clear</mat-icon>
                                        <mat-datepicker-toggle matSuffix [for]="logdate"></mat-datepicker-toggle>
                                        <mat-date-range-picker #logdate twoWayRangeSelect="180"></mat-date-range-picker>
                                    </mat-form-field>
                              
                                    <div class="rangeMsg">Max Date Range 180 Days</div>
                                </div>
                                <!-- <div class="col-12 col-md-6 col-sm-6 col-lg-4">
                                    <mat-form-field appearance="outline" class="custom-mat-form-field">
                                        <mat-label>Log Time From</mat-label>
                                        <input matInput placeholder="Enter Log Time From" [ngxTimepicker]="logStartTimePicker" [format]="timeFormat" 
                                        formControlName='logStartTime'  autocomplete="off" readonly>
                                        <ngx-material-timepicker #logStartTimePicker [theme]="darkTheme"	></ngx-material-timepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-md-6 col-sm-6 col-lg-4">
                                    <mat-form-field appearance="outline" class="custom-mat-form-field">
                                        <mat-label>Log Time Time</mat-label>
                                        <input matInput placeholder="Enter Log Time To" [ngxTimepicker]="logEndTimePicker" [format]="timeFormat" 
                                        formControlName='logEndTime' autocomplete="off" readonly>
                                        <ngx-material-timepicker #logEndTimePicker [theme]="darkTheme"	></ngx-material-timepicker>
                                    </mat-form-field>
                                </div> -->
                            </form>

                        </div>
                        
                        <footer class="text-center mt-5 filterBtn">
                            <button type="button" class="btn-light custom-theme-btn btn mr-2"  (click)="reset()">Reset</button>
                            <button type="button" class="custom-theme-btn btn btn-primary"[disabled]="!advancedSearchForm.valid" (click)="onAdvancedFilterSubmit()" data-dismiss="modal">Search</button>
                        </footer>
                  
            </div>

        </div>
    </div>
</div>