import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router) { }
  public isAuthenticated(): boolean {
    // Check for user role and token and return true or false
    if (localStorage.getItem('userToken') != null)
    {
      return true;
    }
      this.router.navigate(['/login']);
      return false;
  }
}
