import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, private router: Router, @Inject(MAT_DIALOG_DATA) public data: any) { 
    // dialogRef.disableClose = true;
  }
  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close('no');
  }
  okay(){
    this.dialogRef.close('yes');
  }
}

@Component({
  selector: 'app-error-alert-dialog',
  templateUrl: './error-alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class ErrorAlertDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ErrorAlertDialogComponent>, private router: Router, @Inject(MAT_DIALOG_DATA) public data: any) { 
    // dialogRef.disableClose = true;
  }
  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close('no');
  }
  okay(){
    this.dialogRef.close('yes');
  }
}