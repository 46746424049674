<div class="err-msg-dialog  wec-dialog text-center">
    <mat-dialog-content class="mat-typography error_text">
       <div style="padding-right: 28px;"><mat-icon style="font-size: 46px; color: #f44336; ">warning</mat-icon></div>
       <h3 class="err_msg_data">{{ data }}</h3>

    <div class="padding-bottom-30 error_btn">
        <div >
       <button class="mat-raised-button cancel_button " (click)="okay()">OK</button>
    </div>
   </div>
</mat-dialog-content>
 </div>