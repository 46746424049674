import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private title = new BehaviorSubject<String>('App title');
  private title$ = this.title.asObservable();

  private claimManagementClicked = new BehaviorSubject<String>('1');
  private claimManagementClicked$ = this.claimManagementClicked.asObservable();

  private consolidatedClicked = new BehaviorSubject<String>('1');
  private consolidatedClicked$ = this.consolidatedClicked.asObservable();
  constructor() { }
  setTitle(title: String) {
    this.title.next(title);
  }

  getTitle(): Observable<String> {
    return this.title$;
  }

  setClaimManagementClicked(claimManagementClicked: String){
    this.claimManagementClicked.next(claimManagementClicked);
  }
  getClaimManagementClicked(): Observable<String> {
    return this.claimManagementClicked$;
  }
  setConsolidatedClicked(consolidatedClicked: String){
    this.consolidatedClicked.next(consolidatedClicked);
  }
  getConsolidatedClicked(): Observable<String> {
    return this.consolidatedClicked$;
  }
}
