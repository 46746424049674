import {Component, Directive, Inject, Injectable, Input} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {
  MatDateRangeSelectionStrategy,
  DateRange,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import { MaxRangeSelectionStrategy } from '../directives/max-range.directive';

@Injectable()
export class RangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
  public delta: number|any;

  constructor(private _dateAdapter: DateAdapter<D>) {}
  selectionFinished(date: D, currentRange: DateRange<D>) {
    let { start, end } = currentRange;
    if (start == null || (start && end)) {
      start = date;
      end = null;
    } else if (end == null) {       
      end = date;
    }

    if(start && end){
        if(start>end){
          return new DateRange<D>(end, start);          
        }else{
          return new DateRange<D>(start, end);          
        }
    }
    return new DateRange<D>(start, end);
  }
  createPreview(
    activeDate:any,
    currentRange: DateRange<D>
  ): DateRange<D> {
    if (currentRange.start && !currentRange.end) {
        const start = this._dateAdapter.addCalendarDays(currentRange.start, -this.delta);
        const end = this._dateAdapter.addCalendarDays(currentRange.start, this.delta);
        return new DateRange(start, end);
      }
    return new DateRange<D>(null, null);
  }
}
@Directive({
  selector: "[twoWayRangeSelect]",
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: RangeSelectionStrategy
    }
  ]
})
export class TwoWayRangeDirective {
  constructor(
    @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
    private maxRangeStrategy: RangeSelectionStrategy<any>
  ) {}
  @Input() set twoWayRangeSelect(value: string) {
    this.maxRangeStrategy.delta = +(parseInt(value)-1);
  }
 
}
