import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import { Observable, of } from 'rxjs';

import { Router } from "@angular/router";
import {APPCONFIG} from '../shared/config';
@Injectable({
  providedIn: 'root'
})
export class ServiceDocService {
  public url:any = APPCONFIG.serviceDocUrl;
  constructor(private httpClient:HttpClient,private router: Router) {
  
   }

  createHTTPHeader(){
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json',
      'apitoken':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ'
      
    })
    };
    return httpOptions;
  }
  get(url:string): Observable<any> {

    return this.httpClient.get(url);
  }


  post(url:string,data?:any): Observable<any> {
    let httpOptions = this.createHTTPHeader();
    return this.httpClient.post(this.url +"/"+url,data,httpOptions)
  }

  put(url:string,data:any): Observable<any>{
    let httpOptions = this.createHTTPHeader();
    return this.httpClient.put(this.url +"/"+url,data, httpOptions)
  }

  delete(url:string): Observable<any> {
    let httpOptions = this.createHTTPHeader();
    return this.httpClient.delete(this.url +"/"+url, httpOptions)
  }

}

