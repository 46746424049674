import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, share, shareReplay } from 'rxjs/operators';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AppService } from './services/app.service';
import { MatSidenav } from '@angular/material/sidenav';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'spp-app';
  sidenav: boolean = false;
  header: boolean = false;
  showToggle: string | undefined;
  leta=[]
  options:any={
    position: ['top','right'],
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'scale',
  }
  ngOnInit() {

    this.appService
      .getTitle()
      .subscribe((appTitle: any) => (this.title = appTitle));
  }
  _isLoginPage() {
    this.router.events.forEach((event) => {
      //if (event instanceof NavigationStart) {
      if (event instanceof NavigationEnd) {
        if (event['url'] == '/login' || event['url'] == '/') {
          localStorage.clear();
          this.isLoginPage = true;
        } else {
          this.isLoginPage = false;
        }
      }
    });
  }
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe('(max-width: 720px)')
    .pipe(
      map((result) => result.matches),
      shareReplay(1)
    );
  isLoginPage: boolean = true;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private appService: AppService
  ) {
    // on route change to '/login', set the variable showHead to false
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/login') {
          this.sidenav = false;
        } else {
          // console.log("NU")
          this.header = true;
          this.sidenav = true;
        }
      }
    });
    this._isLoginPage();
  }
}
